import React from 'react';
import {
  Box,
  FormHelperText,
  InputBase,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import useStyles from 'pages/SchedulePage/AddEventPage/EventForm/styles';
import Button from 'components/ui/Button';
import { ReactComponent as AddTagIcon } from 'assets/Schedule/new/add-tag.svg';
import Text from 'Text';

const FormInput = withStyles((theme) => ({
  root: {
    flex: 1,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    height: '19px',
    position: 'relative',
    backgroundColor: ' #FFFFFF',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    padding: '12px 16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 8,
      border: '1px solid #082CAF',
    },
  },
}))(InputBase);

export interface IDoctorProps {
  id: string;
  name: string;
  type: string;
  firstname: string;
  lastname: string;
  picture?: string;
  email: string;
  __typename?: string;
  _id: string;
  middlename?: string;
}
interface IPatientSelectProps {
  register: (name: string) => void;
  control: Control;
  errors: DeepMap<Record<string, any>, FieldError>;
  label?: string;
  placeholder?: string;
  loading?: boolean;
  editMode: boolean;
  feedMode: boolean;
  options: { tag: string; color: string; id: string }[];
  selectedColorId: string;
  setSelectedColorId: React.Dispatch<React.SetStateAction<string>>;
  handleClickTag: () => void;
}

export default function TagComponent({
  errors,
  options,
  editMode,
  selectedColorId,
  setSelectedColorId,
  handleClickTag,
  feedMode = false,
  ...props
}: IPatientSelectProps) {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.sideAppointmentPatient} mb={2}>
        <Text i18nKey="tags" className={classes.label}>
          Tags
        </Text>
        <Box
          style={{ opacity: feedMode ? 0.4 : 1 }}
          display="flex"
          alignItems="right"
          className={clsx(classes.topSpacingPatient)}
        >
          <Box
            width="50%"
            display="flex"
            flexDirection="column"
            className={classes.selectDoctor}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ gridGap: '1rem' }}
            >
              <Controller
                {...props}
                name="conditionType"
                render={() => (
                  <Select
                    value={selectedColorId}
                    onChange={(e) =>
                      setSelectedColorId(e.target.value as string)
                    }
                    disabled={feedMode}
                    input={<FormInput />}
                    MenuProps={{
                      disableScrollLock: false,
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                  >
                    {options.length === 0 && (
                      <MenuItem disabled>
                        <Text i18nKey="noTags">No tags</Text>
                      </MenuItem>
                    )}
                    {options.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Box
                          width="100%"
                          height="100%"
                          display="flex"
                          alignItems="center"
                          style={{
                            gap: '0.5rem',
                          }}
                        >
                          <Box
                            width={17}
                            height={17}
                            style={{
                              borderRadius: '50%',
                              backgroundColor: option.color ?? '#BDBDBD',
                              marginRight: '5px',
                            }}
                          ></Box>
                          {option.tag}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <Button
                className={classes.addBtn}
                buttonType="reset"
                onClick={handleClickTag}
                disabled={feedMode}
              >
                <AddTagIcon />
                <Text i18nKey="addTag">Add tag</Text>
              </Button>
            </Box>
            <Box>
              {errors?.conditionType && (
                <FormHelperText className={classes.error}>
                  <ErrorIcon className={classes.errorIcon} />
                  {errors.conditionType?.message}
                </FormHelperText>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
