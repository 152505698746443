import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  free: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 8px',
    backgroundColor: '#F9FAFB',
    border: '1px solid #EAECF0',
    borderRadius: '16px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    color: '#344054',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
  },
  root: {
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px solid #EAECF0',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    padding: '24px 18px',
  },
  icon: {
    '& path': {
      stroke: '#147AF3',
    },
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 25,
    fontWeight: 600,
    color: '#101828',
  },
  btnSettings: {
    color: '#147AF3',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'unset',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  providerAvatar: {
    width: 38,
    height: 38,
  },
  providerName: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#344054',
    paddingLeft: 16,
    textTransform: 'capitalize',
  },
  rowText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#344054',
  },
  paid: {
    border: '1px solid #6FCF97',
    color: '#127850',
    backgroundColor: '#DCFFEB',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  overdue: {
    border: '1px solid #FECDCA',
    color: '#B42318',
    backgroundColor: '#FEF3F2',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  waived: {
    border: '1px solid #D5D9EB',
    color: '#363F72',
    backgroundColor: '#F8F9FC',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  refunded: {
    border: '1px solid #D9D6FE',
    color: '#5925DC',
    backgroundColor: '#F4F3FF',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  pending: {
    border: '1px solid #FEDF89',
    color: '#B54708',
    backgroundColor: '#FFFAEB',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  lastCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // gap: '1rem',
  },
  btnPay: {
    color: '#147AF3',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'unset',
    backgroundColor: 'transparent',
    border: '1px solid #147AF3',
    borderRadius: 8,
    padding: '4px 10px',
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
      '& svg path': {
        stroke: 'white',
      },
      '& $payBtnText': {
        color: 'white',
      },
    },
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  invoiceText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '16px',
    textDecoration: 'underline',
    color: '#147AF3',
    cursor: 'pointer',
  },
  dots: {
    cursor: 'pointer',
  },
  lastColbox: {
    width: '120px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  payBtnText: {
    color: '#147AF3',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'unset',
    '&:hover': {
      color: 'white',
    },
  },
}));
export default useStyles;
