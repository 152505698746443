import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Button from '../ui/Button';
import ProgressLoader from '../ui/ProgressLoader';
import { ReactComponent as DeleteIcon } from 'assets/Schedule/new/delete.svg';
import Text from 'Text';
interface StyleProps {
  isPatient: boolean;
  submitWidth: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    gap: '10px',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: 'white',
    minWidth: '80px',
    maxHeight: '40px',
    minHeight: '40px',
    boxSizing: 'border-box',
    border: '1px solid #D0D5DD',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#344054',
    textTransform: 'capitalize',
    width: 'unset',
    height: 'unset',
    '&:hover': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #425364',
    },
  },
  saveBtnSlide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    minWidth: '80px',
    maxHeight: '40px',
    minHeight: '40px',
    boxSizing: 'border-box',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#1E70DC',
    },
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    minWidth: '80px',
    maxHeight: '40px',
    minHeight: '40px',
    boxSizing: 'border-box',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#1E70DC',
    },
  },
  deleteButton: {
    marginRight: theme.spacing(2),
    backgroundColor: '#FF0303',
    border: '1px solid #FF0303',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '18px',
    lineHeight: '21px',
    minWidth: '80px',
    maxHeight: '40px',
    minHeight: '40px',
    fontWeight: 400,
    borderRadius: ({ isPatient }: StyleProps) => (isPatient ? '25px' : '5px'),
    [theme.breakpoints.only('xl')]: {
      width: '5vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '5vw',
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: '#FF0303',
      border: '1px solid #FF0303',
    },
  },
  deleteButtonSlide: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: theme.spacing(-1),
    width: 'unset',
    height: 'unset',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    color: '#B42318',
    textTransform: 'unset',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: 'transparent',
      border: '1px solid transparent',
    },

    '& svg': {
      marginRight: '0.5rem',
    },
  },
}));
interface IProps {
  editable: boolean;
  handleOpen?: (e: any) => void;
  handleEdit?: (e: any) => void;
  loading?: boolean;
  type?: string;
  px?: string;
  isPatient?: boolean;
  submitText?: string;
  cancelText?: string;
  deleteText?: string;
  editMode?: boolean;
  handleDelete?: () => void;
  submitWidth?: string;
  isSlide?: boolean;
  feedMode?: boolean;
}

export default function EditButtons({
  editable,
  handleOpen,
  handleEdit,
  type,
  loading = false,
  px = '2rem',
  isPatient = false,
  submitText = 'save',
  cancelText = 'Cancel',
  deleteText = 'Delete',
  editMode,
  handleDelete,
  submitWidth,
  isSlide = false,
  feedMode = false,
}: IProps) {
  const classes = useStyles({ isPatient, submitWidth });
  if (editable && isSlide) {
    return (
      <Box className={classes.container} px={px}>
        {editMode && (
          <Button
            data-cy="delete"
            className={classes.deleteButtonSlide}
            onClick={(e) => {
              e.preventDefault();
              handleDelete();
            }}
            type="delete"
            disabled={feedMode}
          >
            <DeleteIcon />
            {deleteText}
          </Button>
        )}
        <Button
          data-cy="cancel"
          className={classes.button}
          onClick={handleOpen}
          buttonType="reset"
        >
          {<Text i18nKey="cancel">{cancelText}</Text>}
        </Button>
        <Button
          variant="contained"
          className={classes.saveBtnSlide}
          style={{
            border: feedMode ? '1px solid #D0D5DD' : '1px solid #2F80ED',
            color: feedMode && 'white',
            backgroundColor: feedMode && '#D0D5DD',
          }}
          onClick={handleEdit}
          buttonType={type}
          disabled={feedMode}
          data-cy="submit"
          type="submit"
        >
          {loading ? (
            <ProgressLoader width={20} height={20} />
          ) : (
            <Text i18nKey={submitText}>{submitText}</Text>
          )}
        </Button>
      </Box>
    );
  }

  if (editable)
    return (
      <Box className={classes.container} px={px}>
        <Button
          data-cy="cancel"
          className={classes.button}
          onClick={handleOpen}
          buttonType="reset"
          type="cancel"
        >
          {cancelText}
        </Button>
        {editMode && (
          <Button
            data-cy="delete"
            className={classes.deleteButton}
            onClick={(e) => {
              e.preventDefault();
              handleDelete();
            }}
            type="delete"
          >
            {deleteText}
          </Button>
        )}
        <Button
          variant="contained"
          className={classes.saveButton}
          onClick={handleEdit}
          buttonType={type}
          data-cy="submit"
          type="submit"
        >
          {loading ? (
            <ProgressLoader width={20} height={20} />
          ) : (
            <Text i18nKey={submitText}>{submitText}</Text>
          )}
        </Button>
      </Box>
    );
  return (
    <Button data-cy="edit" buttonType={type} type="edit" onClick={handleOpen}>
      <Text i18nKey="edit">Edit</Text>
    </Button>
  );
}
EditButtons.defaultProps = {
  handleOpen: () => {},
  handleEdit: () => {},
  loading: false,
  type: '',
};
