import {
  MenuItem,
  Select,
  createStyles,
  withStyles,
  InputBase,
  Box,
  Typography,
} from '@material-ui/core';
import {
  Control,
  Controller,
  FieldValues,
  FieldError,
  DeepMap,
} from 'react-hook-form';

import React from 'react';
interface ISession {
  duration: string;
  rate: string;
  session: string;
}
interface IType {
  options: ISession[] | string[];
  name?: string;
  label?: string;
  control?: Control;
  defaultValue?: string;
  value?: string;
  feedMode?: boolean;
  renderOption?: () => void;
  renderValue?: any;
  children?: React.ReactChild;
  onUpdate?: () => void;
  style?: { width: string; flex?: number };
  className?: string;
  errors?: DeepMap<FieldValues, FieldError>;
  onChange?: (e: any) => void;
  register?: (name: string) => void;
}
export const CustomSelectInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '&.Mui-error > div': {
        border: '1px solid red',
      },
    },
    input: {
      height: '19px',
      position: 'relative',
      backgroundColor: ' #FFFFFF',
      borderRadius: '8px',
      border: '1px solid #D0D5DD',
      fontSize: 16,
      padding: '10px 16px 14px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: 'Inter, sans-serif',
      '&:focus': {
        borderRadius: '8px',
      },
    },
  })
)(InputBase);

export default function CustomSession({
  options,
  onChange,
  onUpdate,
  value,
  feedMode = false,
  ...props
}: IType) {
  return (
    <Select
      variant="filled"
      disabled={feedMode}
      input={<CustomSelectInput error={!!{ ...props }.errors} />}
      {...props}
      MenuProps={{
        disableScrollLock: false,
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
        onUpdate?.();
      }}
      {...props}
    >
      {options?.map((value) => {
        const { session, duration, rate } = value || {};
        return (
          <MenuItem key={value?.name} value={value}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography>{`${duration} min, ${session}`}</Typography>
              <Typography>${rate}</Typography>
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
}

export function RegisteredSelect({
  name,
  label,
  control,
  defaultValue,
  renderOption,
  renderValue,
  children,
  onUpdate,
  style,
  className,
  options,
  errors,
  feedMode = false,
  ...props
}: IType) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <CustomSession
          feedMode={feedMode}
          renderValue={renderValue}
          renderOption={renderOption}
          value={value || ''}
          options={options}
          onChange={(e) => {
            onChange(e);
          }}
          onUpdate={onUpdate}
          style={style}
          className={className}
          errors={errors}
          {...props}
        />
      )}
    />
  );
}
