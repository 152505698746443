import React, { ReactElement } from 'react';
import { Controller, Control } from 'react-hook-form';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { UncontrollerCustomDate } from './CustomDate';
import Text from 'Text';
type CustomDate = string | number | Date;

interface ICustomDateProps {
  control?: Control;
  name: string;
  defaultValue?: [CustomDate, CustomDate];
  label?: string;
  style?: StyleSheetList;
  range: [CustomDate, CustomDate] | [CustomDate];
  onChange?: (
    name: 'startDate' | 'endDate',
    value: Date | string | number
  ) => void;
  dateFormat?: string;
  showTimeSelect?: boolean;
  date?: boolean;
  feedMode?: boolean;
  excludedTimes?: Date[];
  minDate?: Date;
}

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function DateRange({
  dateFormat,
  range,
  onChange,
  showTimeSelect,
  date = false,
  excludedTimes,
  feedMode = false,
  minDate,
  ...props
}: ICustomDateProps): ReactElement {
  const classes = useStyles();
  const [startDate, endDate] = range;
  function handleChangeStartDate(value: any) {
    if (onChange) onChange('startDate', value);
  }
  function handleChangeEndDate(value: any) {
    if (onChange) onChange('endDate', value);
  }
  return (
    <Box display="flex" width="100%" alignContent="center" alignItems="center">
      <UncontrollerCustomDate
        feedMode={feedMode}
        onChange={handleChangeStartDate}
        value={startDate}
        showTimeSelect={showTimeSelect}
        excludedTimes={excludedTimes as Date[]}
        minTime={new Date().setHours(8, 0, 0, 0) as any}
        maxTime={new Date().setHours(17, 0, 0, 0) as any}
        minDate={minDate}
        {...props}
      />
      {!date && (
        <>
          <Text i18nKey="to" className={classes.text}>
            To
          </Text>
          <UncontrollerCustomDate
            onChange={handleChangeEndDate}
            value={endDate}
            showTimeSelect={showTimeSelect}
            minTime={new Date().setHours(9, 0, 0, 0) as any}
            maxTime={new Date().setHours(18, 0, 0, 0) as any}
            minDate={minDate}
            dateFormat={dateFormat}
            {...props}
          />
        </>
      )}
    </Box>
  );
}
