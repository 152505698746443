import React, { ReactElement } from 'react';
import {
  Typography,
  Box,
  BoxProps,
  CircularProgress,
  Avatar,
  InputAdornment,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import useStyles from './styles';
import { ReactComponent as StartIcon } from 'assets/session/play-square.svg';
import { ReactComponent as EndIcon } from 'assets/session/phone-call-02.svg';
import { ReactComponent as CalendarAppt } from 'assets/CalendarAppt.svg';
import { ReactComponent as ClockAppt } from 'assets/ClockAppt.svg';
import { ReactComponent as Refund } from 'assets/Refund.svg';
import dayjs from 'dayjs';
import { useProvider as useMeeting } from 'contexts/MeetingContext';
import Button from 'components/ui/Button';
import { ReactComponent as EditSettings } from 'assets/edit-settings.svg';
import { chipColor } from 'pages/PatientConsultations/ApptCardConsultation';
import Skeleton from 'components/ui/Skeleton';
import { ReactComponent as VideoRoom } from 'assets/videoRoomIcon.svg';
import { FormProvider, useForm } from 'react-hook-form';
import BasicModal from 'components/ui/Modal';
import Table from 'components/ui/Table';
import Checked from 'pages/AdminPage/Payment/Checked';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { ReactComponent as OnlineIcon } from 'assets/online-card.svg';
import { ReactComponent as UsdIcon } from 'assets/currency--dollar.svg';
import { ReactComponent as VideoAppt } from 'assets/VideoAppt.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { TranslationKeys } from 'shared/interfaces/i18';
import { RefundModal } from './RefundModal';
import useRefundAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useRefundAppointemnt';
import useEditSession from '@eggmed/graphql-client/operations/appointmentsOperations/useEditSession';
import FreeTag from 'components/ui/FreeTag';

interface IDoctorHeaderProps extends BoxProps {
  handleAction: () => void;
  firstname: string;
  middlename: string;
  lastname: string;
  currentTime: DateType;
  picture: string;
  isLoading: boolean;
  email: string;
  active?: boolean;
  setOpen?: (open: boolean) => void;
  gender: 'male' | 'female' | 'other' | 'prefer not to say';
  bloodType: string;
  weight: {
    value: string;
    type: string;
  };
  height: {
    value: string;
    type: string;
  };
  birthday: DateType;
  callIsRunning: boolean;
  actionLoading: boolean;
  patientId: string;
  doctorId: string;
  appointment?: Appointment;
  loading: boolean;
  refetchQuery?: () => void;
  handleCreateInvoice: () => void;
}

interface Appointment {
  isEnded?: string;
  isStarted?: boolean;
  isPaid: string;
  startDate: Date;
  paymentIntentId: String;
  _id: string;
  description: string;
  numberOfRepeat: number;
  repeatEvery: number;
  endOn: Date;
  repeatOn: string;
  patient: {
    _id: string;
    firstname: string;
    middlename: string;
    lastname: string;
    picture: string;
    email: string;
    phone: string;
    address?: string;
    suite?: string;
    city?: string;
    zipcode?: string;
    country?: string;
    state?: string;
  };
  doctor: {
    _id: string;
    firstname: string;
    middlename: string;
    lastname: string;
    picture: string;
    email: string;
    phone: string;
    timeZone: string;
    address?: string;
    suite?: string;
    city?: string;
    zipcode?: string;
    country?: string;
    state?: string;
  };
  endDate: Date;
  location: string;
  locationLink: string;
  conditionType: {
    tag: string;
    color: string;
  };
  members: string[];
  patientGoing: boolean;
  doctorGoing: boolean;
  invitation: {
    invitation_id: string;
  };
  sessionType: {
    _id: string;
    __typename: string;
    session: string;
    duration: number;
    rate: number;
    currency: string;
    externalUrl: string;
    locationPlace: string;
  };
  isPrepay: boolean;
}

export default function DoctorHeader({
  firstname,
  middlename,
  lastname,
  currentTime,
  picture,
  isLoading,
  birthday,
  active,
  email,
  handleAction,
  callIsRunning,
  actionLoading,
  patientId,
  doctorId,
  appointment,
  loading,
  setOpen,
  refetchQuery,
  handleCreateInvoice,
  ...props
}: IDoctorHeaderProps): ReactElement {
  const { handleRefund, loadingRefund } = useRefundAppointment();
  const { updateSession, loading: loadingMutation } = useEditSession();
  const theme = useTheme();
  const { callRunning, setCallRunning, endCallAction } = useMeeting();
  const classes = useStyles({ active, callRunning });
  const { sessionType } = appointment || {};

  function closeLocalTestStream() {
    if (!appointment?.isStarted) {
      const { sessionType } = appointment || {};
      const session = {
        session: sessionType?.session,
        currency: sessionType?.currency,
        duration: sessionType?.duration.toString(),
        rate: sessionType?.rate.toString(),
      };
      return updateSession(appointment?._id, {
        sessionType: session,
        isStarted: true,
      });
    }
  }

  async function handleEndCall() {
    if (appointment?.isPaid !== 'paid') {
      setOpen(true);
      endCallAction();
    } else {
      setOpenRefund(true);
      endCallAction();
    }
  }
  function handleParticipate() {
    if (!callIsRunning && !callRunning) {
      closeLocalTestStream();
      setCallRunning(true);
      if (
        appointment?.sessionType?.externalUrl &&
        appointment?.sessionType?.locationPlace === 'Zoom'
      ) {
        window.open(appointment?.sessionType?.externalUrl, '_blank');
      } else {
        handleAction();
      }
    } else {
      handleEndCall();
    }
  }

  const refundForms = useForm();
  const [toggled, setToggle] = React.useState(false);
  function handleChange(e) {
    setToggle((togl) => !togl);
  }
  const [openRefund, setOpenRefund] = React.useState(false);
  function handleCloseRefund() {
    setOpenRefund(false);
    setToggle(false);
  }
  async function onRefund(data) {
    const { startDate, endDate, paymentIntentId } = appointment || {};
    if (data?.refund) {
      await handleRefund(paymentIntentId as string, Number(data?.refund) * 100);
    }
    const session = {
      session: appointment?.sessionType?.session,
      currency: appointment?.sessionType?.currency,
      duration: appointment?.sessionType?.duration.toString(),
      rate: appointment?.sessionType?.rate.toString(),
    };
    await updateSession(appointment?._id, {
      sessionType: session,
      isEnded: true,
      endSessionDate: new Date(),
    });
    refetchQuery();
    endCallAction();
    await handleCreateInvoice();
    handleCloseRefund();
    localStorage.removeItem('timer');
    localStorage.removeItem(`Appointment_${appointment?._id}`);
  }
  const columns = [
    {
      id: 'startDate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="date">Date</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 170,
      format: (value) => {
        return (
          <Typography className={classes.textRow}>
            {dayjs(value).format('MMM D, YYYY')}
          </Typography>
        );
      },
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="fees">Fees</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 170,
      format: (value) => {
        return (
          <Typography className={classes.textRow}>{value?.rate} USD</Typography>
        );
      },
    },
    {
      id: 'isPaid',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="paymentStatus">Payment status</Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value) => {
        return (
          <Box
            style={{
              width: '50%',
              borderRadius: '16px',
              background: chipColor[value]?.background,
              border: chipColor[value]?.border,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px 8px',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                color: chipColor[value]?.color,
                textTransform: 'capitalize',
              }}
            >
              {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'sessionType',
      label: <Box fontSize="1rem">SessionType</Box>,
      minWidth: 170,
      align: 'left',
      format: (value) => (
        <Typography className={classes.textRow}>{value?.session}</Typography>
      ),
    },
  ];
  const { t } = useTranslation();
  return (
    <Box>
      {!appointment?.isEnded && !appointment?.isStarted && !loading ? (
        <Box className={classes.endBox}>
          <Box width="100%">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{
                overflow: 'hidden',
                border: '1px solid #147AF37D',
                borderRadius: '16px',
                background:
                  'linear-gradient(54.32deg, rgba(20, 122, 243, 0.2205) -14.81%, rgba(63, 191, 127, 0.225) 121.69%)',
              }}
            >
              <Box p={2}>
                <Box display="flex" alignItems="center" gridGap="1rem">
                  <Box display="flex" alignItems="center">
                    <Skeleton loading={loading}>
                      <CalendarAppt style={{ marginRight: '5px' }} />
                    </Skeleton>
                    <Skeleton loading={loading}>
                      <Typography className={classes.date}>
                        {dayjs(appointment?.startDate).format('MMM DD, YYYY')}
                      </Typography>
                    </Skeleton>
                  </Box>
                  <Box display="flex" alignItems="center" ml={1}>
                    <Skeleton loading={loading}>
                      <ClockAppt style={{ marginRight: '5px' }} />
                    </Skeleton>
                    <Skeleton loading={loading}>
                      <Typography className={classes.date}>
                        {dayjs(appointment?.startDate)
                          .tz(appointment?.doctor?.timeZone)
                          .format('h:mm A')}{' '}
                        -{' '}
                        {dayjs(appointment?.endDate)
                          .tz(appointment?.doctor?.timeZone)
                          .format('h:mm A')}{' '}
                      </Typography>
                    </Skeleton>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gridGap="0.3rem"
                    mr="0.7rem"
                  >
                    {appointment?.locationLink ? <VideoAppt /> : <OnlineIcon />}
                    <Typography className={classes.desc}>
                      {appointment?.locationLink ? t('Online') : t('In-person')}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    gridGap="0.3rem"
                    mr="0.5rem"
                  >
                    <UsdIcon />

                    {!(sessionType?.rate == 0) && (
                      <Typography className={classes.desc}>
                        {appointment?.sessionType.rate}{' '}
                        {appointment?.sessionType.currency}
                      </Typography>
                    )}
                  </Box>

                  {sessionType?.rate == 0 ? (
                    <FreeTag />
                  ) : (
                    <Box
                      className={classes.paidChip}
                      style={{
                        border: chipColor[appointment?.isPaid]?.border,
                        color: chipColor[appointment?.isPaid]?.color,
                      }}
                    >
                      {t(`${appointment?.isPaid as TranslationKeys}`)}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.bottomCardWrapper}
                >
                  <Box
                    className={classes.consultationButton}
                    display="flex"
                    style={{ cursor: 'pointer' }}
                    onClick={handleParticipate}
                  >
                    <Box>
                      <Skeleton loading={loading}>
                        {!callRunning ? (
                          <StartIcon style={{ marginTop: '2px' }} />
                        ) : (
                          <EndIcon style={{ marginTop: '2px' }} />
                        )}
                      </Skeleton>
                    </Box>
                    <Skeleton loading={loading}>
                      <Typography className={classes.consultationText}>
                        {!callRunning ? t('Start session') : t('End session')}
                      </Typography>
                    </Skeleton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : !appointment?.isEnded && appointment?.isStarted && !loading ? (
        <Box className={classes.endBox}>
          <Box width="100%">
            <Box
              style={{
                overflow: 'hidden',
                border: '1px solid #147AF37D',
                borderRadius: '16px',
                background:
                  'linear-gradient(54.32deg, rgba(20, 122, 243, 0.2205) -14.81%, rgba(63, 191, 127, 0.225) 121.69%)',
              }}
            >
              <Box p={2}>
                <Box display="flex" alignItems="center" gridGap="1rem">
                  <Box display="flex" alignItems="center">
                    <Skeleton loading={loading}>
                      <CalendarAppt style={{ marginRight: '5px' }} />
                    </Skeleton>
                    <Skeleton loading={loading}>
                      <Typography className={classes.date}>
                        {dayjs(appointment?.startDate).format('MMM DD, YYYY')}
                      </Typography>
                    </Skeleton>
                  </Box>
                  <Box display="flex" alignItems="center" ml={1}>
                    <Skeleton loading={loading}>
                      <ClockAppt style={{ marginRight: '5px' }} />
                    </Skeleton>
                    <Skeleton loading={loading}>
                      <Typography className={classes.date}>
                        {dayjs(appointment?.startDate)
                          .tz(appointment?.doctor?.timeZone)
                          .format('h:mm A')}{' '}
                        -{' '}
                        {dayjs(appointment?.endDate)
                          .tz(appointment?.doctor?.timeZone)
                          .format('h:mm A')}{' '}
                      </Typography>
                    </Skeleton>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gridGap="0.3rem"
                    mr="0.7rem"
                  >
                    {appointment?.locationLink ? <VideoAppt /> : <OnlineIcon />}
                    <Typography className={classes.desc}>
                      {appointment?.locationLink ? t('Online') : t('In-person')}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    gridGap="0.3rem"
                    mr="0.5rem"
                  >
                    <UsdIcon />
                    {!(sessionType?.rate == 0) && (
                      <Typography className={classes.desc}>
                        {appointment?.sessionType.rate}{' '}
                        {appointment?.sessionType.currency}
                      </Typography>
                    )}
                  </Box>

                  {sessionType?.rate == 0 ? (
                    <FreeTag />
                  ) : (
                    <Box
                      className={classes.paidChip}
                      style={{
                        border: chipColor[appointment?.isPaid]?.border,
                        color: chipColor[appointment?.isPaid]?.color,
                      }}
                    >
                      {t(`${appointment?.isPaid as TranslationKeys}`)}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.bottomCardEnd}
                >
                  <Box display="flex" alignItems="center" gridGap="1rem">
                    <Box
                      className={classes.endConsultationButton}
                      display="flex"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleEndCall()}
                    >
                      <Box>
                        <Skeleton loading={loading}>
                          <EndIcon style={{ marginTop: '2px' }} />
                        </Skeleton>
                      </Box>
                      <Skeleton loading={loading}>
                        <Text i18nKey="End session" className={classes.endCall}>
                          End session
                        </Text>
                      </Skeleton>
                    </Box>
                    {!callRunning && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        className={classes.rejointbnhover}
                      >
                        <Button
                          className={classes.rejoinBtn}
                          onClick={handleParticipate}
                        >
                          <VideoRoom style={{ marginRight: '10px' }} />
                          <Text i18nKey="rejoin">Rejoin</Text>
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        !loading && (
          <Box className={classes.endBox}>
            <Box width="100%">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                style={{
                  overflow: 'hidden',
                  border: '1px solid #147AF37D',
                  borderRadius: '16px',
                }}
              >
                <Box p={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Skeleton loading={loading}>
                        <CalendarAppt style={{ marginRight: '5px' }} />
                      </Skeleton>
                      <Skeleton loading={loading}>
                        <Typography className={classes.date}>
                          {dayjs(appointment?.startDate).format('MMM DD, YYYY')}
                        </Typography>
                      </Skeleton>
                    </Box>
                    <Box display="flex" alignItems="center" ml={1}>
                      <Skeleton loading={loading}>
                        <ClockAppt style={{ marginRight: '5px' }} />
                      </Skeleton>
                      <Skeleton loading={loading}>
                        <Typography className={classes.date}>
                          {dayjs(appointment?.startDate)
                            .tz(appointment?.doctor?.timeZone)
                            .format('h:mm A')}{' '}
                          -{' '}
                          {dayjs(appointment?.endDate)
                            .tz(appointment?.doctor?.timeZone)
                            .format('h:mm A')}{' '}
                        </Typography>
                      </Skeleton>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1} mb={1}>
                    <Box
                      display="flex"
                      alignItems="center"
                      gridGap="0.3rem"
                      mr="0.7rem"
                    >
                      {appointment?.locationLink ? (
                        <VideoAppt />
                      ) : (
                        <OnlineIcon />
                      )}
                      <Typography className={classes.desc}>
                        {appointment?.locationLink
                          ? t('Online')
                          : t('In-person')}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      gridGap="0.3rem"
                      mr="0.5rem"
                    >
                      <UsdIcon />
                      {!(sessionType?.rate == 0) && (
                        <Typography className={classes.desc}>
                          {appointment?.sessionType.rate}{' '}
                          {appointment?.sessionType.currency}
                        </Typography>
                      )}
                    </Box>

                    {sessionType?.rate == 0 ? (
                      <FreeTag />
                    ) : (
                      <Box
                        className={classes.paidChip}
                        style={{
                          border: chipColor[appointment?.isPaid]?.border,
                          color: chipColor[appointment?.isPaid]?.color,
                        }}
                      >
                        {t(`${appointment?.isPaid as TranslationKeys}`)}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    className={classes.bottomCardWrapper}
                  >
                    <Skeleton loading={loading}>
                      <Button
                        className={classes.editBtn}
                        onClick={() => handleEndCall()}
                      >
                        <EditSettings />
                        <Text i18nKey="editPayment">Edit payment</Text>
                      </Button>
                    </Skeleton>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton loading={loading}>
                        <Text
                          i18nKey="sessionEnded"
                          className={classes.endedText}
                        >
                          Session ended
                        </Text>
                      </Skeleton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      )}
      <FormProvider {...refundForms}>
        <BasicModal
          open={openRefund}
          onClose={handleCloseRefund}
          handleClose={handleCloseRefund}
          isFromModal
          isSlide
          divider
          title={t('Complete your session')}
          titlePadding="2rem"
          onSubmit={onRefund}
          loading={loadingRefund || loadingMutation}
        >
          <RefundModal
            isLoading={isLoading}
            appointment={appointment}
            columns={columns}
            toggled={toggled}
            handleChange={handleChange}
            refundForms={refundForms}
          />
        </BasicModal>
      </FormProvider>
    </Box>
  );
}
