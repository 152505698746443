import React, { ReactElement } from 'react';
import Modal, { ModalProps } from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Slide, Typography, Grid } from '@material-ui/core';
import ModalForm from './ModalForm';
import { ReactComponent as CancelIcon } from 'assets/icons/Vector.svg';
import RegularModal from './RegularModal';
import SlideModalForm from './SlideModalForm';
import SlideRegularModal from './SlideRegularModal';
import NewRegularModal from './NewRegularModal';
import NoteTakingModal from './NoteTakingModal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  children: {
    outline: 0,
    maxHeight: '90%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  slide: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
}));

interface IProps extends ModalProps {
  onSubmit?: (e: any) => void;
  handleClose?: () => void;
  isFromModal?: boolean;
  isNoteTaking?: boolean;
  isRegularModal?: boolean;
  divider?: boolean;
  loading?: boolean;
  titlePadding?: string;
  disablePadding?: boolean;
  isIcon?: boolean;
  isPatient?: boolean;
  titleClass?: string;
  submitText?: string;
  deleteText?: string;
  cancelText?: string;
  editMode?: boolean;
  handleDelete?: () => void;
  isSlide?: boolean;
  isNew?: boolean;
  feedMode?: boolean;
  btnsPadding?: string;
  hasTitle?: boolean;
  direction?: 'left' | 'up' | 'right' | 'down';
  handleEditStep?: () => void;
}

export default function BasicModal({
  feedMode = false,
  open,
  onClose,
  title,
  children,
  className,
  onSubmit,
  handleClose,
  isFromModal,
  isNoteTaking,
  divider,
  loading,
  isRegularModal,
  titlePadding,
  disablePadding = false,
  isIcon = true,
  isPatient = false,
  titleClass,
  submitText,
  deleteText,
  cancelText,
  editMode,
  handleDelete,
  isSlide = false,
  isNew = false,
  btnsPadding,
  hasTitle = true,
  direction = 'left',
  handleEditStep = null,
}: IProps): ReactElement {
  const classes = useStyles({ openSuccess: false });

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      className={isSlide ? classes.slide : classes.modal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableScrollLock={false}
    >
      {isSlide ? (
        <Slide direction={direction} in={open} mountOnEnter unmountOnExit>
          <Box style={{ outline: 'none', paddingTop: '0px' }}>
            {isFromModal ? (
              <SlideModalForm
                feedMode={feedMode}
                handleClose={handleClose}
                onSubmit={onSubmit}
                className={className}
                divider={divider}
                loading={loading}
                title={title}
                titlePadding={titlePadding}
                disablePadding={disablePadding}
                isPatient={isPatient}
                submitText={submitText}
                deleteText={deleteText}
                cancelText={cancelText}
                editMode={editMode}
                handleDelete={handleDelete}
                btnsPadding={btnsPadding}
                hasTitle={hasTitle}
                handleEditStep={handleEditStep}
              >
                {children}
              </SlideModalForm>
            ) : isRegularModal ? (
              <SlideRegularModal
                title={title}
                handleClose={handleClose}
                titlePadding={titlePadding}
                isIcon={isIcon}
                className={className}
                titleClass={titleClass}
                divider={divider}
              >
                {React.cloneElement(children, { handleClose })}
              </SlideRegularModal>
            ) : (
              <Box className={classes.children}>{children}</Box>
            )}
          </Box>
        </Slide>
      ) : (
        <Fade in={open}>
          {isFromModal ? (
            <ModalForm
              handleClose={handleClose}
              onSubmit={onSubmit}
              className={className}
              divider={divider}
              loading={loading}
              title={title}
              titlePadding={titlePadding}
              disablePadding={disablePadding}
              isPatient={isPatient}
              submitText={submitText}
              editMode={editMode}
              handleDelete={handleDelete}
              hasTitle={hasTitle}
              btnsPadding={btnsPadding}
            >
              {children}
            </ModalForm>
          ) : isRegularModal ? (
            isNew ? (
              <NewRegularModal
                title={title}
                handleClose={handleClose}
                titlePadding={titlePadding}
                isIcon={isIcon}
                className={className}
                titleClass={titleClass}
              >
                {children}
              </NewRegularModal>
            ) : (
              <RegularModal
                title={title}
                handleClose={handleClose}
                titlePadding={titlePadding}
                isIcon={isIcon}
                className={className}
                titleClass={titleClass}
              >
                {children}
              </RegularModal>
            )
          ) : (
            <Box className={classes.children}>{children}</Box>
          )}
        </Fade>
      )}
    </Modal>
  );
}
BasicModal.defaultProps = {
  isFromModal: false,
  divider: false,
  loading: false,
  isRegularModal: false,
  titlePadding: '2rem',
};
