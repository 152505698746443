import React, { FC, useCallback, useState, useRef, useEffect } from 'react';
import { List, Box } from '@material-ui/core';
import SearchInput from 'components/ui/SearchInput';
import { debounce } from 'lodash';
import ScrollBar from 'components/ui/ScrollBar';
import { GetAllThreadConversationByUser_getAllThreadConvertationByUser } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAllThreadConversationByUser';
import ConversationItem from './ConversationItem';
import useStyles from './styles';
import { useProvider } from 'pages/InboxPage/state/Provider';
import SearchItemListCard from './SearchItemListCard';
import { useLocation } from 'react-router-dom';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Skeleton from 'components/ui/Skeleton';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyInbox } from 'assets/emptyState/inbox.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

interface IConversationListProps {
  isArchived?: boolean;
  data: GetAllThreadConversationByUser_getAllThreadConvertationByUser[];
  handleSelected: (threadId: string) => void;
  selected: string;
}
const ConversationList: FC<IConversationListProps> = ({
  data,
  handleSelected,
  selected,
  isArchived,
}) => {
  const { t } = useTranslation();
  const { patient } = useAuth();
  const classes = useStyles({ isPatient: patient ? true : false });

  const { handleSelectedThead, handelPaginationConversationList } =
    useProvider();

  const handleScrollPagination = useCallback(() => {
    handelPaginationConversationList(isArchived);
  }, [data, isArchived]);

  const location = useLocation<string>();
  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('messageId');
  useEffect(() => {
    if (location.state || redirectStatus)
      handleSelectedThead(location.state || redirectStatus);
  }, [location.state, redirectStatus]);
  return (
    <div className={classes.fullHeight}>
      <ScrollBar
        handleScrollBottom={handleScrollPagination}
        className={classes.conversationListContainer}
      >
        {data ? (
          <List>
            {data?.map(
              (
                item: GetAllThreadConversationByUser_getAllThreadConvertationByUser,
                index: number
              ) => (
                <>
                  <ConversationItem
                    key={index}
                    data={item}
                    handleSelected={handleSelected}
                    selected={selected}
                    messages={data}
                  />
                  {/* <Divider style={{ marginInline: '1rem' }} /> */}
                </>
              )
            )}
          </List>
        ) : (
          <List>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(() => (
              <Box display="flex" pt={2} px={4} style={{ gap: '1rem' }}>
                <Box>
                  <Skeleton
                    loading={!data}
                    type="circle"
                    width="55px"
                    height="55px"
                  >
                    <></>
                  </Skeleton>
                </Box>
                <Box display="flex" justifyContent="space-between" flex={1}>
                  <Box>
                    <Skeleton
                      loading={!data}
                      type="text"
                      width="7rem"
                      height="20px"
                    >
                      <></>
                    </Skeleton>
                    <Skeleton
                      loading={!data}
                      type="text"
                      width="5rem"
                      height="20px"
                    >
                      <></>
                    </Skeleton>
                  </Box>
                  <Skeleton
                    loading={!data}
                    type="text"
                    width="5rem"
                    height="20px"
                  >
                    <></>
                  </Skeleton>
                </Box>
              </Box>
            ))}
          </List>
        )}
        {data?.length === 0 && (
          <Box pt="7.5rem">
            <EmptyState
              icon={<EmptyInbox />}
              title={t('No threads')}
              text={
                isArchived
                  ? t('Archived conversations will appear here')
                  : t('Active conversations will appear here')
              }
              noActionText={true}
              hasAction={false}
            />
          </Box>
        )}
      </ScrollBar>
    </div>
  );
};

export default ConversationList;
