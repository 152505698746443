import { Box, FormHelperText, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import DateRange from 'components/ui/Inputs/DateRange';
import ProgressLoader from 'components/ui/ProgressLoader';
import { ReactComponent as NoTimeIllustration } from 'assets/illustrations/NoTimesIllustration.svg';
import { IDateRange } from '../types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import Text from 'Text';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';

dayjs.extend(customParseFormat);

interface IDateAndTimeProps {
  dateRange: IDateRange;
  handleChangeDate: (
    name: 'startDate' | 'endDate',
    value: string | number
  ) => void;
  loadingFreeTimes: boolean;
  getExactFreeTime: string[];
  handleClick: (index: number, times: string) => void;
  setTestIndex: (test: boolean) => void;
  isActive: number;
  isDefaultTime: (element: string) => boolean;
  pickedRightTime: boolean;
  feedMode?: boolean;
}

export const DateAndTime = ({
  dateRange,
  handleChangeDate,
  loadingFreeTimes,
  getExactFreeTime,
  handleClick,
  setTestIndex,
  isActive,
  isDefaultTime,
  pickedRightTime,
  feedMode = false,
}: IDateAndTimeProps) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const language = i18n.language || 'en';
  dayjs.locale(language === 'fr' ? 'fr' : 'en');

  const formatTime = (time, language) => {
    return language === 'fr'
      ? dayjs(time, 'HH:mm', true).format('HH:mm')
      : dayjs(time, 'HH:mm', true).format('h:mm A');
  };

  return (
    <Box mb={2}>
      <Box
        className={classes.dateTimeContainer}
        style={{ border: pickedRightTime && '1px solid red' }}
        mb={pickedRightTime && 1}
      >
        <Text i18nKey="dateAndTime" className={classes.label}>
          Date & time
        </Text>
        <Box width="55%" style={{ opacity: feedMode ? 0.4 : 1 }}>
          <DateRange
            dateFormat={language === 'fr' ? 'D MMMM YYYY' : 'MMMM D, YYYY'}
            range={[dateRange.startDate]}
            name="daterange"
            onChange={!feedMode ? handleChangeDate : undefined}
            date
            minDate={new Date()}
            feedMode={feedMode}
          />
        </Box>
        <Box
          style={{ opacity: feedMode ? 0.4 : 1 }}
          display="flex"
          flexWrap="wrap"
          mt={1.7}
          mb={0}
          width="100%"
          justifyContent="space-between"
        >
          {loadingFreeTimes ? (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              mt={2}
              mb={2}
            >
              <ProgressLoader width={60} height={60} />
            </Box>
          ) : (
            <Box
              display="flex"
              flexWrap="wrap"
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                gap: '2%',
              }}
            >
              {getExactFreeTime?.length > 0 ? (
                getExactFreeTime?.map((el, index) => (
                  <Box
                    className={classes.freeTimes}
                    onClick={() => {
                      handleClick(index, el);
                      setTestIndex(true);
                    }}
                    style={{
                      userSelect: 'none',
                      backgroundColor:
                        (isActive == index || isDefaultTime(el)) && '#147AF3',
                      color:
                        (isActive == index || isDefaultTime(el)) && 'white',
                    }}
                  >
                    {formatTime(el, language)}
                  </Box>
                ))
              ) : (
                <Box width="100%">
                  <Box className={classes.emptyIllustration}>
                    <Text
                      i18nKey="noAvailableTimes"
                      className={classes.textTimesPatient}
                    >
                      No available times
                    </Text>
                    <Box className={classes.illus}>
                      <NoTimeIllustration />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box alignSelf="flex-start">
        {pickedRightTime && (
          <FormHelperText data-cy="error-message" className={classes.error}>
            <ErrorIcon className={classes.errorIcon} />
            Selection of available session is required
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};
