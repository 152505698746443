import { Box, Checkbox, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Text from 'Text';
import { Controller } from 'react-hook-form';

interface IRequirePrepayment {
  isActivePayout?: boolean;
  control: any;
  isFree?: boolean;
  feedMode?: boolean;
}

export const RequirePrepayment = ({
  isActivePayout,
  control,
  isFree = false,
  feedMode,
}: IRequirePrepayment) => {
  const classes = useStyles({ isActivePayout });
  return (
    <Box
      className={classes.requirePrepayContainer}
      style={{ opacity: isFree || feedMode || !isActivePayout ? 0.4 : 1 }}
      mb={2}
    >
      <Box>
        <Typography className={classes.requirePrepayTitle}>
          <Text i18nKey="requirePrePayment">Require prepayment</Text>
        </Typography>
        <Text
          i18nKey="Client must pay session fees in order to join"
          className={classes.requirePrepayDesc}
        >
          Client must pay session fees in order to join{' '}
        </Text>
      </Box>
      <Controller
        name={'isPrepay'}
        control={control}
        render={({ onChange, value }) => {
          return (
            <Checkbox
              size="medium"
              checked={value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              checkedIcon={<CheckedIcon />}
              icon={<UncheckedIcon />}
              style={{ backgroundColor: 'transparent' }}
              disabled={!isActivePayout || feedMode || isFree}
            />
          );
        }}
      />
    </Box>
  );
};
