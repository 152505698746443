import React, { FC } from 'react';
import { Grid, Avatar, Box, Typography, Divider } from '@material-ui/core';
import { GetAllThreadConversationByUser_getAllThreadConvertationByUser } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAllThreadConversationByUser';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useStyles from './styles';
import vector from 'assets/dashboard/Vector.svg';
import { useHistory } from 'react-router-dom';
import { fromNow } from 'utils';
import { truncateText, capitalizedText } from 'utils/FormatText';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Eye } from 'assets/eye-icon.svg';

interface IMessagesDasbhoardElementProps {
  index: number;
  data: GetAllThreadConversationByUser_getAllThreadConvertationByUser;
}
const MessagesDasbhoardElement: FC<IMessagesDasbhoardElementProps> = ({
  data,
  index,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { _id, creator, lastMessage, updatedAt } = data || {};
  const handleClick = () => {
    history.push('/doctor/inbox', _id);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      // mt={index != 0 ? 2 : 0}
      key={index}
      className={classes.boxWrapper}
      onClick={() => handleClick()}
    >
      <Avatar src={creator.picture} alt="picture" />
      <Box ml={2} flex={1}>
        <Box display="flex" alignItems="center" gridGap={8} maxWidth="80%">
          <Typography className={classes.doctorName}>
            {capitalizedText(creator.firstname)}{' '}
            {capitalizedText(creator.middlename)}
            {capitalizedText(creator.lastname)}
          </Typography>
          <Typography className={classes.date}>{fromNow(updatedAt)}</Typography>
        </Box>
        <Box display="flex">
          <Typography className={classes.lastMsgSent}>
            {user._id === lastMessage.user._id && `${t('you')}: `}
            {truncateText(lastMessage.content.text, 30)}
            &nbsp;
          </Typography>
        </Box>
      </Box>

      <Box className={classes.hoverIcon}>
        <Eye />
      </Box>
    </Box>
  );
};
export default MessagesDasbhoardElement;
