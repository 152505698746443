import React, { useEffect, useState } from 'react';
import { Typography, Box, Divider, TextField } from '@material-ui/core';
import { useStyles } from '../styles';
import { IPatientActivities } from '..';
import dayjs from 'dayjs';
import { IEmoji } from './Form';
import { TypeEnum } from './Activity';
import Button from 'components/ui/Button';
import { ReactComponent as ReflectionsIcon } from 'assets/patient-profile/response/reflection.svg';
import { ReactComponent as MoodIcon } from 'assets/patient-profile/response/mood.svg';
import { ReactComponent as SleepIcon } from 'assets/patient-profile/response/sleep.svg';
import { ReactComponent as DownloadIcon } from 'assets/patient-profile/response/download.svg';
import { ReactComponent as MessageIcon } from 'assets/patient-profile/response/msg.svg';
import { ReactComponent as SettingsIcon } from 'assets/patient-profile/settings.svg';
import { ReactComponent as MoonStarIcon } from 'assets/patient-profile/response/moon-star.svg';
import { ReactComponent as SunIcon } from 'assets/patient-profile/response/sun.svg';
import { Link } from 'react-router-dom';
import { FrequencyResult } from './FrequencyResult';
import { ReflectionType } from './useHandlePatientActivities';
import { useProvider as useInobx } from 'pages/InboxPage/state/Provider';
import { ReactComponent as Saved } from 'assets/Etoile.svg';
import { ReactComponent as SavedYellow } from 'assets/EtoileJaune.svg';
import { ReactComponent as GuidedIcon } from 'assets/patient-profile/guided.svg';
import { ReactComponent as FreeIcon } from 'assets/patient-profile/free-notes.svg';
import BasicModal from 'components/ui/Modal';
import ActivityModal from './ActivityModal';
import { config } from 'config';
import { ActivitiesTypes } from '../RecentActivityCard';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

export const modalTitle = (activityType, formTitle) => {
  switch (activityType) {
    case TypeEnum.MOOD:
      return 'Mood check-in';
    case ActivitiesTypes.MOOD:
      return 'Mood check-in';
    case TypeEnum.SLEEP:
      return 'Sleep check-in';
    case ActivitiesTypes.SLEEP:
      return 'Sleep check-in';
    case TypeEnum.REFLECTION:
      return 'Reflection';
    case ActivitiesTypes.REFLECTION:
      return 'Reflection';
    case ActivitiesTypes.FORM:
      return formTitle ?? '';
    default:
      return '';
  }
};

function ResultModal({
  activities,
  activityType,
  selectedDay,
  handleEmoji,
  emojis,
  currentRowId,
  currentPdf,
  onClose,
  handleBookMark,
  doctorAndPatientId,
  isFeed,
}: {
  activities: IPatientActivities[];
  activityType: TypeEnum;
  selectedDay?: string;
  handleEmoji: (emoji: string, emojis: IEmoji[]) => JSX.Element;
  emojis?: IEmoji[];
  currentRowId?: string;
  currentPdf?: string;
  onClose?: () => void;
  handleBookMark?: (id: string, bookmarked: boolean) => void;
  doctorAndPatientId: { doctor: string; patient: string };
  isFeed?: boolean;
}) {
  const classes = useStyles({});
  const { handleOpenCreateNewThreadModal } = useInobx();
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language || 'en');
  const modalType = {
    [TypeEnum.MOOD]: () => (
      <ActivityModal
        name={selectedResponse?.patient?.firstname}
        handleClose={() => setOpen(false)}
        doctorAndPatientId={doctorAndPatientId}
        defaultValues={selectedResponse}
        type={TypeEnum.MOOD}
      />
    ),
    [TypeEnum.SLEEP]: () => (
      <ActivityModal
        name={selectedResponse?.patient?.firstname}
        handleClose={() => setOpen(false)}
        doctorAndPatientId={doctorAndPatientId}
        defaultValues={selectedResponse}
        type={TypeEnum.SLEEP}
      />
    ),
    [TypeEnum.REFLECTION]: () => (
      <ActivityModal
        name={selectedResponse?.patient?.firstname}
        handleClose={() => setOpen(false)}
        defaultValues={selectedResponse}
        doctorAndPatientId={doctorAndPatientId}
        type={TypeEnum.REFLECTION}
      />
    ),
  };

  const modalIcon = {
    [TypeEnum.MOOD]: () => <MoodIcon />,
    [TypeEnum.SLEEP]: () => <SleepIcon />,
    [TypeEnum.REFLECTION]: () => <ReflectionsIcon />,
  };

  const selectedResponse = activities.filter(
    (el) => el._id === currentRowId
  )[0];

  const [bookmark, setBookmark] = useState<boolean>(
    selectedResponse.bookmarked
  );

  useEffect(() => {
    setBookmark(selectedResponse.bookmarked);
  }, [selectedResponse.bookmarked]);

  return (
    <>
      <Box className={classes.resultModalBox}>
        <Box className={classes.responseHeader}>
          <Box>{modalIcon[activityType]()}</Box>
          <Box>
            <Box className={classes.cardTitleWrapper}>
              <Typography className={classes.responseHeaderTitle}>
                {t(modalTitle(activityType, 'form'))}
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleBookMark(selectedResponse._id, !bookmark);
                  setBookmark((s) => !s);
                }}
              >
                {bookmark ? <SavedYellow /> : <Saved />}
              </Box>
              <Box className={classes.guidedOrFree}>
                {activityType === TypeEnum.REFLECTION &&
                selectedResponse?.reflectionType === ReflectionType.Guided ? (
                  <>
                    <GuidedIcon /> {t('Guided')}{' '}
                  </>
                ) : (
                  <>
                    <FreeIcon /> {t('Free notes')}{' '}
                  </>
                )}
              </Box>
            </Box>

            <Typography className={classes.responseHeaderDate}>
              <Link
                to={selectedResponse?.patient?._id}
                className={classes.responseNameInside}
              >
                {selectedResponse?.patient?.firstname}{' '}
                {selectedResponse?.patient?.lastname}
              </Link>{' '}
              {t('on')}{' '}
              {dayjs(
                new Date(selectedResponse?.patientResponse[0].createdAt)
              ).format(
                i18n.language === 'fr'
                  ? 'dddd, D MMM YYYY - HH:mm'
                  : 'dddd, D MMM YYYY - h:mmA'
              )}
            </Typography>
            <Box className={classes.headerBtns}>
              {currentPdf && (
                <a
                  href={currentPdf}
                  target="_blank"
                  className={classes.downloadBtn}
                >
                  <DownloadIcon />
                  Download PDF
                </a>
              )}
              <Button
                className={classes.msgBtn}
                onClick={() => {
                  handleOpenCreateNewThreadModal();
                }}
              >
                <MessageIcon />
                Message
              </Button>
              <Button
                className={classes.msgBtn}
                onClick={() => {
                  setOpen(true);
                }}
                disabled={isFeed}
              >
                <SettingsIcon style={{ opacity: isFeed ? 0.4 : 1 }} />
                {t('Settings')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box mx="2rem" my={3}>
          {selectedResponse?.frequency?.endDate && (
            <FrequencyResult
              endDate={selectedResponse?.frequency?.endDate}
              repetition={selectedResponse?.frequency?.repetition}
            />
          )}
          <Divider className={classes.divider} />
          {activityType === TypeEnum.REFLECTION && (
            <>
              <Typography className={classes.question}>
                {selectedResponse?.reflectionType === ReflectionType.Guided
                  ? t(selectedResponse?.question)
                  : t('Free note')}
              </Typography>
              <TextField
                name="response"
                className={classes.textField}
                variant="outlined"
                multiline
                minRows={2}
                value={selectedResponse?.patientResponse[0]?.response}
                disabled
              />
              {selectedResponse?.reflectionType === ReflectionType.Guided && (
                <>
                  {selectedResponse?.patientResponse[0]?.image && (
                    <>
                      <Divider className={classes.divider} />
                      <img
                        src={selectedResponse?.patientResponse[0]?.image}
                        alt="image"
                        className={classes.reflectionImg}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {activityType === TypeEnum.MOOD && (
            <>
              <Typography className={classes.question}>
                {t(selectedResponse?.question)}
              </Typography>
              <Box className={classes.emojiResponse}>
                {handleEmoji(
                  selectedResponse?.patientResponse[0]?.response,
                  emojis
                )}
                <Typography className={classes.emojiResponseText}>
                  {t(selectedResponse?.patientResponse[0]?.response)}
                </Typography>
              </Box>
              {selectedResponse?.patientResponse[0]?.image && (
                <>
                  <img
                    src={selectedResponse?.patientResponse[0]?.image}
                    alt="image"
                    className={classes.reflectionImg}
                  />
                </>
              )}
              <Typography className={classes.textLabel}>Note</Typography>
              <TextField
                name="response"
                className={classes.textField}
                variant="outlined"
                multiline
                minRows={2}
                value={selectedResponse?.patientResponse[0]?.notes}
                disabled
              />
            </>
          )}
          {activityType === TypeEnum.SLEEP && (
            <>
              <Typography className={classes.question}>
                {t(selectedResponse?.question)}
              </Typography>
              <Box className={classes.emojiResponse}>
                {handleEmoji(
                  selectedResponse?.patientResponse[0]?.response,
                  emojis
                )}
                <Typography className={classes.emojiResponseText}>
                  {t(selectedResponse?.patientResponse[0]?.response)}
                </Typography>
              </Box>
              <Typography className={classes.textLabel}>
                {t('Sleep hours')}
              </Typography>
              <Box className={classes.sleepData}>
                <Box className={classes.sleepBedTime}>
                  <MoonStarIcon />
                  {t('Bed time')}:
                  <span style={{ fontWeight: 700 }}>
                    {dayjs(
                      new Date(
                        Number(selectedResponse?.patientResponse[0]?.sleepHours)
                      )
                    ).format(i18n.language === 'fr' ? 'HH:mm' : 'h:mmA')}
                  </span>
                </Box>
                <Box className={classes.sleepWakeupTime}>
                  <SunIcon />
                  {t('Wake up')}:
                  <span style={{ fontWeight: 700 }}>
                    {dayjs(
                      new Date(
                        Number(selectedResponse?.patientResponse[0]?.wakeHours)
                      )
                    ).format(i18n.language === 'fr' ? 'HH:mm' : 'h:mmA')}
                  </span>
                </Box>
                <Box className={classes.sleepTotal}>
                  Total:{' '}
                  <span style={{ fontWeight: 700 }}>
                    {Math.abs(
                      dayjs(
                        new Date(
                          Number(
                            selectedResponse?.patientResponse[0]?.sleepHours
                          )
                        )
                      ).diff(
                        dayjs(
                          new Date(
                            Number(
                              selectedResponse?.patientResponse[0]?.wakeHours
                            )
                          )
                        ).isBefore(
                          dayjs(
                            new Date(
                              Number(
                                selectedResponse?.patientResponse[0]?.sleepHours
                              )
                            )
                          )
                        )
                          ? dayjs(
                              new Date(
                                Number(
                                  selectedResponse?.patientResponse[0]
                                    ?.wakeHours
                                )
                              )
                            ).add(1, 'day')
                          : dayjs(
                              new Date(
                                Number(
                                  selectedResponse?.patientResponse[0]
                                    ?.wakeHours
                                )
                              )
                            ),
                        'hour'
                      )
                    )}{' '}
                    {t('hours')}
                  </span>
                </Box>
              </Box>
              <Typography className={classes.textLabel}>Note</Typography>
              <TextField
                name="response"
                className={classes.textField}
                variant="outlined"
                multiline
                minRows={2}
                value={selectedResponse?.patientResponse[0]?.notes}
                disabled
              />
              {selectedResponse?.patientResponse[0]?.image && (
                <>
                  <Divider className={classes.divider} />
                  <img
                    src={selectedResponse?.patientResponse[0]?.image}
                    alt="image"
                    className={classes.reflectionImg}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <BasicModal
        isRegularModal
        isSlide
        titlePadding="0"
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
      >
        {modalType[activityType]()}
      </BasicModal>
    </>
  );
}

export default ResultModal;
