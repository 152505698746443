import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FreeTag = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      textAlign="center"
      style={{
        backgroundColor: '#DCFFEB',
        border: '1px solid #6FCF97',
        borderRadius: '16px',
        padding: '2px 8px',
        color: '#127850',
        fontStyle: 'italic',
        fontSize: '12px',
        fontWeight: 500,
        fontFamily: 'Inter',
        boxSizing: 'border-box',
        width: 'fit-content',
        marginLeft: '2px',
      }}
    >
      {t('Free')}
    </Box>
  );
};

export default FreeTag;
