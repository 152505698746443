import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Button,
  Theme,
  Avatar,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useDeleteAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useDeleteAppointment';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import Skeleton from 'pages/PatientGeneralInfo/../../components/ui/Skeleton';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as ReminderLogo } from 'assets/ReminderLogo.svg';
import useSetReminder from '@eggmed/graphql-client/operations/appointmentsOperations/useSetReminder';
import useGenerateAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useGenerateAppointment';
import { ReactComponent as InPersonAppt } from 'assets/InPersonAppt.svg';
import { ReactComponent as CalendarSession } from 'assets/CalendarSession.svg';
import { ReactComponent as ClockSession } from 'assets/ClockSession.svg';
import { ReactComponent as OnlineSession } from 'assets/OnlineSession.svg';
import { ReactComponent as CurrencySession } from 'assets/CurrencySession.svg';
import { useStyles } from 'pages/PatientDashboard/styles';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { dateFormatDependancy } from 'utils/dateUtils';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import FreeTag from 'components/ui/FreeTag';

export const chipColor = {
  paid: {
    background: '#ECFDF3',
    color: '#067647',
    border: '1px solid #ABEFC6',
  },
  pending: {
    background: '#FFFAEB',
    color: '#B54708',
    border: '1px solid #FEDF89 ',
  },
  overdue: {
    background: '#FFC6C5',
    color: '#D71300',
    border: '1px solid #FFC6C5 ',
  },
  waived: {
    background: 'rgba(86, 127, 189, 0.40)',
    color: '#567FBD',
    border: '1px solid rgba(86, 127, 189, 0.40) ',
  },
  refunded: {
    border: '1px solid #D9D6FE',
    color: '#5925DC',
    background: '#F4F3FF',
  },
};

const ApptCard = ({ event, loading }) => {
  const { patient: patientAuth } = useAuth();
  const { deleteAppointment } = useDeleteAppointment();
  const { handleGenerate, loadingGenerate } = useGenerateAppointment();

  const { handleEditCurrentEvent } = useProvider();
  const { setReminder } = useSetReminder();
  const { t } = useTranslation();

  const history = useHistory();
  const {
    startDate,
    endDate,
    locationLink,
    isPaid,
    patient,
    _id,
    sessionType,
    doctor,
    invitation,
    isEnded,
  } = event || {};
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  function handleOpenDeleteModal() {
    setOpenDeleteModal(true);
  }
  const handelDelete = (id: string) => {
    deleteAppointment(id, true, null);
    setOpenDeleteModal(false);
  };
  const lowerAction = [
    {
      name: t('Cancel'),
      icon: null,
      action: () => {
        handleOpenDeleteModal();
      },
    },
    {
      name: t('Reschedule'),
      icon: null,
      action: () => {
        handleEditCurrentEvent(event);
      },
    },
    {
      name: 'Join',
      icon: null,
      action: () => {
        history.push(`/meeting/${invitation?.invitation_id}`);
      },
    },
  ];
  const exactArray =
    !locationLink && patientAuth
      ? lowerAction?.filter((el) => el?.name !== 'Join')
      : lowerAction;

  const pastActions = [
    {
      name: 'Summary',
      icon: null,
      action: () => {
        handleGenerate(_id);
      },
    },
    {
      name: 'Reminder',
      icon: null,
      action: () => {
        handleOpen();
      },
    },
  ];
  const actions =
    isPaid === 'pending'
      ? pastActions
      : pastActions?.filter((el) => el?.name !== 'Reminder');
  const isPrevious = new Date(endDate) < new Date();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  async function handleReminder(id: string) {
    await setReminder({
      variables: {
        appointmentId: id,
      },
    });
    handleClose();
  }

  return (
    <>
      <Box className={clsx(classes.container, classes.upperBox)} mb={2}>
        <Box className={classes.upperBox}>
          <Box
            display="flex"
            alignItems="center"
            p={2}
            style={{
              border: '1px solid #D0D5DD',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              gridGap={5}
            >
              <Avatar src={doctor?.picture} className={classes.picture}>
                {doctor?.firstname &&
                  capitalizeFirstLetter(doctor?.firstname[0])}{' '}
                {doctor?.lastname && capitalizeFirstLetter(doctor?.lastname[0])}
              </Avatar>
              <Box>
                <Typography className={classes.doctorLastName}>
                  {doctor?.firstname}
                </Typography>
                <Typography className={classes.doctorLastName}>
                  {doctor?.lastname}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" ml={2}>
              <Box display="flex" alignItems="center" mb={0}>
                <Box display="flex" alignItems="center" mr={1}>
                  <CalendarSession style={{ marginRight: '10px' }} />
                  <Typography className={classes.labelTxt}>
                    {dayjs(startDate).format('MMM D')}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mr={1}>
                  <ClockSession style={{ marginRight: '10px' }} />
                  <Typography className={classes.labelTxt}>
                    {dateFormatDependancy(
                      startDate,
                      patientAuth?.formatDate
                      // patient?.timeZone
                    )}{' '}
                    -{' '}
                    {dateFormatDependancy(
                      endDate,
                      patientAuth?.formatDate
                      // patient?.timeZone
                    )}
                  </Typography>
                </Box>{' '}
              </Box>

              <Box display="flex" alignItems="center" mb={0}>
                {locationLink ? (
                  <OnlineSession style={{ marginRight: '10px' }} />
                ) : (
                  <InPersonAppt style={{ marginRight: '10px' }} />
                )}
                {locationLink ? (
                  <Text i18nKey="onlineSession" className={classes.labelTxt}>
                    {' '}
                    Online session
                  </Text>
                ) : (
                  <Text i18nKey="inPersonSession" className={classes.labelTxt}>
                    {' '}
                    In-person session
                  </Text>
                )}
              </Box>
              <Box display="flex" alignItems="center">
                <CurrencySession style={{ marginRight: '10px' }} />
                {!(parseInt(sessionType?.rate) === 0) && (
                  <Typography className={classes.labelTxt}>
                    {sessionType?.rate} {sessionType?.currency}{' '}
                  </Typography>
                )}
                {parseInt(sessionType?.rate) === 0 ? (
                  <FreeTag />
                ) : (
                  <Box ml={1}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        color: chipColor[isPaid]?.color,
                        textTransform: 'capitalize',
                        backgroundColor: chipColor[isPaid]?.background,
                        border: chipColor[isPaid]?.border,
                        padding: '5px 10px',
                        borderRadius: '16px',
                      }}
                    >
                      {t(isPaid)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.lowerBox}>
          {!isEnded ? (
            <Box
              className={clsx(classes.lower, classes.lowerBox)}
              display="flex"
            >
              {exactArray?.map((el, key) => (
                <Box
                  py={2}
                  px={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  textAlign="center"
                  style={{
                    cursor: 'pointer',
                    backgroundColor: el?.name === 'Join' ? '#147AF3' : 'white',
                    border: '1px solid #B6CDF2',
                    borderBottomLeftRadius: key === 0 && '16px',
                    borderBottomRightRadius:
                      key === exactArray.length - 1 && '16px',
                    padding: '15px',
                  }}
                  onClick={el.action}
                >
                  {el.icon && (
                    <Skeleton loading={loading}>
                      <Box mr={1}>{el.icon}</Box>
                    </Skeleton>
                  )}
                  <Skeleton loading={loading}>
                    <Typography
                      style={{
                        textAlign: 'center',
                        color:
                          el?.name === 'Cancel'
                            ? '#EB5757'
                            : el?.name === 'Join'
                            ? 'white'
                            : '#344054',
                        fontSize: '14px',
                        fontWeight: 600,
                        fontFamily: 'Inter',
                      }}
                    >
                      {t(el.name)}
                    </Typography>
                  </Skeleton>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              className={classes.lower}
              display="flex"
              border="1px solid #D0D5DD"
              borderTop="none"
              style={{
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px',
              }}
            >
              {actions?.map((el, key) => (
                <Box
                  py={1.8}
                  px={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  textAlign="center"
                  style={{
                    borderRight:
                      key === 0 && isPaid === 'pending' && '1px solid #D0D5DD',
                    cursor: 'pointer',
                  }}
                  onClick={el.action}
                >
                  {el.icon && (
                    <Skeleton loading={loading}>
                      <Box mr={1}>{el.icon}</Box>
                    </Skeleton>
                  )}
                  <Skeleton loading={loading}>
                    <Typography style={{ textAlign: 'center' }}>
                      {t(el.name)}
                    </Typography>
                  </Skeleton>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>

      <ModalDelete
        text="session"
        open={openDeleteModal}
        onClose={() => handleCloseDeleteModal()}
        onDelete={() => handelDelete(_id)}
      />
      <BasicModal
        open={open}
        onClose={handleClose}
        isRegularModal
        title=""
        titlePadding="3rem"
        handleClose={handleClose}
        isIcon={false}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          px={8}
          pb={4}
        >
          <ReminderLogo />
          <Box>
            <Text
              i18nKey="Send Payment Reminder"
              className={classes.paymentTitle}
            >
              Send Payment Reminder
            </Text>
            <Text
              i18nKey="Are you sure you want to send reminders to"
              className={classes.paymentText}
            >
              Are you sure you want to send reminders to
            </Text>
            <Text
              i18nKey="complete payment process?"
              className={classes.paymentText}
            >
              complete payment process?
            </Text>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Button className={classes.btnC} onClick={handleClose}>
              <Text i18nKey="cancel">Cancel</Text>
            </Button>
            <Button
              className={classes.btnSC}
              onClick={() => handleReminder(_id)}
            >
              <Text i18nKey="send">Send</Text>
            </Button>
          </Box>
        </Box>
      </BasicModal>
    </>
  );
};

export default ApptCard;
