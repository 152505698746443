import React from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as ReloadIcon } from 'assets/ReloadIcon.svg';
import { ReactComponent as CloseDepIcon } from 'assets/CloseDepIcon.svg';

const DeploymentComponents = () => {
  const deploymentResult = useSubscription(
    gql`
      subscription subscribeToDeployment {
        subscribeToDeployment
      }
    `,
    {
      onSubscriptionData: (data) => {
        console.log(data, 'data');
      },
    }
  );
  console.log(deploymentResult, 'deploymentResult');
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (deploymentResult?.data?.subscribeToDeployment) {
      setOpen(deploymentResult?.data?.subscribeToDeployment);
    }
  }, [deploymentResult?.data?.subscribeToDeployment]);
  if (open)
    return (
      <Box
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100vw',
          backgroundColor: '#A6EF67',
          height: '76px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 9999999999999999999999999999999999,
        }}
      >
        <Box></Box>
        <Box display="flex" alignItems="center">
          <Typography
            style={{
              fontWeight: 600,
              fontSize: '18px',
              color: '#182230',
              fontFamily: 'Inter',
            }}
          >
            New update available! 😎{' '}
          </Typography>
          <Button
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Inter',
              fontWeight: 600,
              color: '#182230',
              fontSize: '14px',
              backgroundColor: 'white',
              border: '1px solid #FFFFFF',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              borderRadius: '8px',
              padding: '8px 12px',
              textTransform: 'none',
              marginLeft: '16px',
            }}
            onClick={() => window.location.reload()}
          >
            <ReloadIcon style={{ marginRight: '5px' }} />
            Reload app
          </Button>
        </Box>{' '}
        <Box pr="2rem">
          <CloseDepIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
          />
        </Box>
      </Box>
    );
  return null;
};

export default DeploymentComponents;
