import React, { ReactElement } from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';

export interface IRouteProps extends RouteProps {
  nestedRoutes?: IRouteProps[];
  layout?: React.ComponentType<any>;
  layoutProps?: {
    isMargin?: boolean;
    isBackgroundGrey?: boolean;
  };
}

interface Props {
  routes: IRouteProps[];
}
const RoutesGenerator = ({ routes }: Props): ReactElement => {
  const renderRoutes = (
    baseRoute: string,
    Layout: React.ComponentType<any>
  ) => {
    return (route: IRouteProps, i) => {
      if (route?.nestedRoutes)
        return route?.nestedRoutes.map(
          renderRoutes(`${baseRoute}${route.path}`, route.layout || Layout)
        );

      return (
        <Route
          key={`Element i ${i}`}
          {...route}
          path={`${baseRoute}${route.path}`}
          component={(props) => {
            return route.layout ? (
              <route.layout {...route.layoutProps}>
                {<route.component {...props} />}
              </route.layout>
            ) : Layout ? (
              <Layout {...route.layoutProps}>
                {<route.component {...props} />}
              </Layout>
            ) : (
              <route.component {...props} />
            );
          }}
        />
      );
    };
  };
  return (
    <Switch>
      {routes.length > 0 ? (
        routes.map(renderRoutes('', null))
      ) : (
        <Route path="*" render={() => <h1>404</h1>} />
      )}
    </Switch>
  );
};
export default RoutesGenerator;
