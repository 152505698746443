import { Box, Checkbox, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Text from 'Text';
import { Controller } from 'react-hook-form';
import Switch from 'components/ui/SwitchNew';

export const useStyles = makeStyles((theme) => ({
  requirePrepayContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '8px 12px',
    gap: '15px',
    width: '50%',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    flex: 'none',
    order: 0,
    flexGrow: 1,
  },
  requirePrepayTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    color: '#344054',
  },
  requirePrepayDesc: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: '4px',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 0,
  },
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '6px',
    margin: '0 auto',
    order: 0,
    flexGrow: 1,
  },
}));

export const IsDefaultComponent = (props) => {
  const classes = useStyles();
  const isNotRequiredPrepayment = props?.isPrepayment && props?.isFreeValue;
  return (
    <Box
      className={classes.requirePrepayContainer}
      mb={2}
      mt={3}
      style={{
        width: props?.isPrepayment && '100%',
        opacity: isNotRequiredPrepayment ? 0.4 : 1,
      }}
    >
      <Box className={classes.wrap}>
        <Box className={classes.title}>
          {props?.icon}
          <Typography className={classes.requirePrepayTitle}>
            {props?.title}
          </Typography>
        </Box>
        <Typography className={classes.requirePrepayDesc}>
          {props?.description}
        </Typography>
      </Box>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props?.isDefault || true}
        render={({ onChange, value, ref }) => {
          if (props.type === 'switch')
            return (
              <Switch
                checked={value}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                inputRef={ref}
                disabled={props?.isDefault}
              />
            );
          return (
            <Checkbox
              size="medium"
              checked={value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              checkedIcon={<CheckedIcon />}
              icon={<UncheckedIcon />}
              style={{ backgroundColor: 'transparent' }}
              disabled={props?.isDefault}
            />
          );
        }}
      />
    </Box>
  );
};
