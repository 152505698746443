import { gql, useLazyQuery, useQuery } from '@apollo/client';
import React, { ReactElement, useState, useEffect } from 'react';
import { Grid, Avatar, Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import Skeleton from '../../components/ui/Skeleton';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';
import DataLoader from 'components/ui/DataLoader';
import dayjs from 'dayjs';
import { capitalizedText } from 'utils/FormatText';
import Messages from './MessagesDasbhoard';
import { ReactComponent as AddPatientIcon } from 'assets/AddPatientIcon.svg';
import { ReactComponent as ChangeWorkingHourIcon } from 'assets/ChangeWorkingHourIcon.svg';
import { ReactComponent as ScheduleAppIcon } from 'assets/ScheduleAppIcon.svg';
import { ReactComponent as Eye } from 'assets/eye-icon.svg';
import { useProvider } from 'pages/AppointmentListPage/state/Provider';
import Task from 'pages/Taskpage';
import { useHistory } from 'react-router-dom';
import Modals from 'pages/InboxPage/modals/index';
import { useProvider as useInobx } from 'pages/InboxPage/state/Provider';

import {
  useProvider as useSchedule,
  CONFLICT_APPOINTMENT,
} from 'pages/SchedulePage/state/SchedulePageProvider';
import { ReactComponent as UpcomingEmpty } from 'assets/dashboard/upcoming-empty.svg';
import bgLines from 'assets/dashboard/background-lines.png';
import Button from 'components/ui/Button';
import ScrollBar from 'components/ui/ScrollBar';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'assets/dashboard/plus.svg';
import { FinancesHeader } from 'components/FinancesHeader';
import { Period } from 'pages/DoctorFinancePage/DoctorFinance';
import { ReactComponent as AlertIcon } from 'assets/AlertTriangle.svg';
import { STRIPE_ACCOUNT } from 'pages/AdminPage/Payment/Payouts';
import useAddCheckPermission from 'contexts/AddPermissionOntext';

const DashboardPage = (): ReactElement => {
  const { isEnabled, loading: loadingAccess } =
    useAddCheckPermission('Clients');
  const { isEnabled: isEnabledToSchedule } = useAddCheckPermission('Schedule');
  const { handleOpenAddModal, handleOpenPatientModal, handleEditCurrentEvent } =
    useSchedule();
  const { handleOpenCreateNewThreadModal } = useInobx();
  const history = useHistory();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Dashboard' });
  }, [headerTitleDispatcher]);
  // TODO: Change it to a single request

  const classes = useStyles();
  const [appointmentPagination, setAppointment] = React.useState(null);
  const [
    getAppointment,
    { loading: loadingA, data: dataA, error: errorA, refetch },
  ] = useLazyQuery(CONFLICT_APPOINTMENT);
  const upcomingSessions =
    appointmentPagination?.doctorUpcomingAppointments?.result;
  const [isloading, setIsLaoding] = React.useState(true);
  const [time, setTime] = useState<string>(Period.week.toUpperCase());
  const [limit, setLimit] = React.useState(10);
  function handleNext() {
    setIsLaoding(false);
    const newLimit = limit + 4;
    setLimit(newLimit);
    getAppointment({
      variables: {
        filter: {
          limit: newLimit,
          skip: 0,
        },
      },
    });
  }

  React.useEffect(() => {
    getAppointment({
      variables: {
        filter: { limit, skip: 0 },
      },
    });
  }, [getAppointment]);

  React.useEffect(() => {
    if (dataA) {
      setAppointment(dataA);
      refetch();
    }
  }, [dataA, refetch]);

  function mappedData() {
    const data = {};

    upcomingSessions?.forEach((item) => {
      const startDate = new Date(item?.startDate);
      const monthName = startDate.toLocaleString('default', { month: 'long' });

      if (!data[monthName]) {
        data[monthName] = [];
      }

      data[monthName].push(item);
    });

    // Sort the months based on the earliest appointment date within each month
    const sortedData = {};
    Object.keys(data)
      .sort((a, b) => {
        const earliestDateA =
          data[a].length > 0 ? new Date(data[a][0].startDate) : null;
        const earliestDateB =
          data[b].length > 0 ? new Date(data[b][0].startDate) : null;
        return (earliestDateA as any) - (earliestDateB as any);
      })
      .forEach((monthName) => {
        sortedData[monthName] = data[monthName];
      });

    return sortedData;
  }
  const { t } = useTranslation();

  const upcomingAppointments = mappedData();
  const upcomingKeys = Object.keys(upcomingAppointments);
  const upcomingValues = Object.values(upcomingAppointments) as any[];
  const { data: dataFinance } = useProvider();

  const actions = [
    {
      title: t('Add client'),
      icon: <AddPatientIcon />,
      description: t('Create a new client'),
      click: isEnabled
        ? () => {
            handleOpenPatientModal();
          }
        : undefined,
    },
    {
      icon: <ChangeWorkingHourIcon />,
      title: t('Schedule session'),
      description: t('Arrange a session with a client'),
      click: isEnabledToSchedule
        ? () => {
            handleOpenAddModal();
          }
        : undefined,
    },
    {
      icon: <ScheduleAppIcon />,
      title: t('Manage availability'),
      description: t('Update your working hours'),
      click: () => {
        history.push('/doctor/admin?query=workingHours');
      },
    },
  ];
  const { data, loading } = useQuery(STRIPE_ACCOUNT);
  const { payouts_enabled, charges_enabled } = data?.doctorStripeAccount || {};
  return (
    <DataLoader error={errorA} data={dataA}>
      <>
        {(!payouts_enabled || !charges_enabled) && !loading && (
          <Grid xl={12} lg={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.payoutAlert}
              px={2}
              py={2.5}
              mb={2}
            >
              <Box display="flex" alignItems="center">
                <AlertIcon style={{ marginRight: '8px' }} />
                <Typography className={classes.alertMsg}>
                  {t(
                    'Missing payout information, set up your payout method to receive payments.'
                  )}
                </Typography>
              </Box>
              <Button
                className={classes.managePayout}
                onClick={() => history.push('/doctor/admin?tab=13')}
              >
                {t('Manage payout settings')}
              </Button>
            </Box>
          </Grid>
        )}
        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <Grid item xl={4} lg={4}>
            <Box px={3} py={3} className={classes.boxWhite}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Skeleton
                  loading={loadingA && isloading}
                  type="text"
                  width="200px"
                  height="27px"
                >
                  <Text
                    i18nKey="upcomingSessions"
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '18px',
                      fontWeight: 600,
                      color: '#101828',
                    }}
                  >
                    Upcoming sessions
                  </Text>
                </Skeleton>
                <Skeleton
                  loading={loadingA && isloading}
                  type="text"
                  width="55px"
                  height="21px"
                >
                  {upcomingKeys.length !== 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      gridGap={6}
                      style={{ opacity: !isEnabledToSchedule ? 0.4 : 1 }}
                      onClick={
                        isEnabledToSchedule
                          ? () => handleOpenAddModal()
                          : undefined
                      }
                    >
                      <PlusIcon
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                      <Text
                        i18nKey="addNew"
                        style={{
                          fontFamily: 'Inter, sans-serif',
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#147AF3',
                          cursor: 'pointer',
                        }}
                      >
                        Add new
                      </Text>
                    </Box>
                  )}
                </Skeleton>
              </Box>
              <ScrollBar
                mt={2}
                height="500px"
                style={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
                onScroll={(e: any) => {
                  if (
                    e.target.scrollHeight - e.target.scrollTop ===
                    e.target.clientHeight
                  ) {
                    handleNext();
                  }
                }}
                isClass={false}
              >
                {loadingA && isloading ? (
                  <Box>
                    <Box style={{ backgroundColor: '#EAECF0' }} px={2} py={0.5}>
                      <Skeleton
                        loading={loadingA && isloading}
                        type="text"
                        width="100px"
                        height="21px"
                      >
                        <></>
                      </Skeleton>
                    </Box>
                    {[1, 2, 3, 4]?.map((el, key) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        py={2}
                        borderBottom={key !== 3 && '1px solid #EAECF0'}
                      >
                        <Skeleton
                          loading={loadingA && isloading}
                          type="circle"
                          width="40px"
                          height="40px"
                        >
                          <></>
                        </Skeleton>
                        <Box ml={2}>
                          <Skeleton
                            loading={loadingA && isloading}
                            type="text"
                            width="100px"
                            height="21px"
                          >
                            <></>
                          </Skeleton>
                          <Skeleton
                            loading={loadingA && isloading}
                            type="text"
                            width="120px"
                            height="21px"
                          >
                            <></>
                          </Skeleton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : upcomingKeys.length !== 0 ? (
                  upcomingKeys?.map((element, index) => (
                    <Box>
                      <Box
                        style={{ backgroundColor: '#EAECF0' }}
                        px={2}
                        py={0.5}
                      >
                        <Typography
                          style={{
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                            fontWeight: 500,
                            color: '#101828',
                          }}
                        >
                          {element === dayNames[new Date().getDay()]
                            ? 'Today'
                            : capitalizedText(t(element))}
                        </Typography>
                      </Box>
                      {upcomingValues[index]?.map((el, key) => (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          py={2}
                          className={classes.session}
                          borderBottom={
                            key !== upcomingValues[index]?.length - 1 &&
                            '1px solid #EAECF0'
                          }
                          onClick={() => handleEditCurrentEvent(el)}
                        >
                          <Box display="flex" alignItems="center">
                            <Avatar
                              src={el?.patient?.picture}
                              style={{ fontSize: '14px' }}
                            >
                              {` ${
                                el?.patient?.firstname &&
                                el?.patient?.firstname[0].toUpperCase()
                              }${
                                el?.patient?.lastname &&
                                el?.patient?.lastname[0].toUpperCase()
                              } `}
                            </Avatar>
                            <Box ml={2}>
                              <Typography
                                style={{
                                  fontFamily: 'Inter, sans-serif',
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  color: '#101828',
                                }}
                              >
                                {capitalizedText(el?.patient?.firstname)}{' '}
                                {capitalizedText(el?.patient?.lastname)}
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: 'Inter, sans-serif',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  color: '#475467',
                                }}
                              >
                                {dayjs(el?.startDate)
                                  .tz(el?.doctor?.timeZone)
                                  .format('ddd D MMM YYYY - HH:mm')}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className={classes.hoverIcon}>
                            <Eye />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  ))
                ) : (
                  <Box className={classes.emptyUpcoming}>
                    <img src={bgLines} className={classes.bgImg} alt="bg" />
                    <UpcomingEmpty />
                    <Text
                      i18nKey="No sessions yet. Lets get started!"
                      className={classes.emptyTitle}
                    >
                      No sessions yet. Let's get started!
                    </Text>
                    <Text
                      i18nKey="Click here to schedule your first session and organize your
                    day."
                      className={classes.emptySecTitle}
                    >
                      Click here to schedule your first session and organize
                      your day.
                    </Text>
                    <Button
                      className={classes.scheduleBtn}
                      onClick={() => handleOpenAddModal()}
                    >
                      Schedule session
                    </Button>
                  </Box>
                )}
              </ScrollBar>
            </Box>
            <Box mt={2} px={3} py={3} className={classes.boxWhite}>
              <Messages
                loading={loadingA && isloading}
                handleOpenCreateNewThreadModal={handleOpenCreateNewThreadModal}
              />
            </Box>
          </Grid>
          <Grid item xl={8} lg={8}>
            <Grid container spacing={1}>
              {actions?.map((action, index) => (
                <Grid item xl={4} lg={4} className={classes.actionItem}>
                  <Box
                    className={classes.boxWhite}
                    display="flex"
                    alignItems="center"
                    px={2}
                    py={2}
                    style={{
                      cursor: 'pointer',
                      flexGrow: 1,
                      opacity:
                        (!isEnabled && index === 0) ||
                        (!isEnabledToSchedule && index === 1)
                          ? 0.4
                          : 1,
                    }}
                    onClick={action.click}
                  >
                    <Skeleton
                      loading={loadingA && isloading}
                      type="rect"
                      width="50px"
                      height="50px"
                      className={classes.actionIconRadius}
                    >
                      {action.icon}
                    </Skeleton>
                    <Box ml={2}>
                      <Skeleton
                        loading={loadingA && isloading}
                        type="text"
                        width="100px"
                        height="21px"
                      >
                        <Typography className={classes.titleAction}>
                          {action.title}
                        </Typography>
                      </Skeleton>
                      <Skeleton
                        loading={loadingA && isloading}
                        type="text"
                        width="120px"
                        height="21px"
                      >
                        <Typography className={classes.descriptionAction}>
                          {action.description}
                        </Typography>
                      </Skeleton>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <FinancesHeader
              dataFinance={dataFinance}
              loading={loadingA && isloading}
              time={time}
              setTime={setTime}
              isDashboard
            />
            <Box mt={2} px={3} py={3} className={classes.boxWhite}>
              <Task isDashboard />
            </Box>
          </Grid>
          <Modals />
        </Grid>
      </>
    </DataLoader>
  );
};

export default DashboardPage;
export const GET_EVENT_DATA = gql`
  query getEventData($startDate: Date, $endDate: Date, $doctors: [ID]) {
    doctorPatients {
      _id
      firstname
      middlename
      email
      lastname
      picture
    }
    doctors {
      email
      picture
      firstname
      middlename
      lastname
      _id
    }
    eventsByDate(startDate: $startDate, endDate: $endDate) {
      result {
        _id
        description
        title
        numberOfRepeat
        repeatEvery
        endOn
        repeatOn
        isImpact
        doctor {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
        }
        startDate
        endDate
      }
    }
    appointmentsByDate(
      startDate: $startDate
      endDate: $endDate
      doctors: $doctors
    ) {
      result {
        _id
        description
        numberOfRepeat
        repeatEvery
        endOn
        repeatOn
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
        }
        doctor {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
        }
        startDate
        endDate
        location
        locationLink
        conditionType {
          tag
          color
        }
        members
        patientGoing
        doctorGoing
        invitation {
          invitation_id
        }
        sessionType {
          session
          duration
          rate
          currency
        }
        isPrepay
      }
    }
  }
`;
export const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
