import React, { useState } from 'react';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';
import BasicModal from 'components/ui/Modal';
import { Box, Typography, Avatar, Button, Tooltip } from '@material-ui/core';
import CustomButton from 'components/ui/Button';
import useStyles from './styles';
import SelectMenu from './MenuName';
import Table from 'components/ui/Table';
import { useProvider } from 'pages/AppointmentListPage/state/Provider';
import dayjs from 'dayjs';
import { IColumn } from 'components/ui/Table/types';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ReminderLogo } from 'assets/ReminderLogo.svg';
import useSetReminder from '@eggmed/graphql-client/operations/appointmentsOperations/useSetReminder';
import useWaivePayment from '@eggmed/graphql-client/operations/appointmentsOperations/useWaivePayment';
import Skeleton from 'components/ui/Skeleton';
import Modals from 'pages/InboxPage/modals/index';
import { useProvider as useInobx } from 'pages/InboxPage/state/Provider';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { GET_FINANCES } from '@eggmed/graphql-client/operations/appointmentsOperations/useGetFinances';
import WaiveModal from './WaiveModal';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'hooks/useSnackbar';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyPayment } from 'assets/emptyState/payment.svg';
import Text from 'Text';

import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';
import { ReactComponent as SettingsIcon } from 'assets/dashboard/finances/settings-02.svg';
import { CreateInvoiceFlow } from './invoice/CreateInvoiceFlow';
import { transformArrayTimeZone } from 'utils';
import { FinancesHeader } from 'components/FinancesHeader';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DotsIcon } from 'assets/dashboard/finances/dots.svg';
import { InvoicePreview } from './invoice/InvoicePreview';
import Menu from 'components/ui/Menu';
import { ReactComponent as InvoiceIcon } from 'assets/dashboard/finances/invoice.svg';
import { ReactComponent as PaymentIcon } from 'assets/dashboard/finances/coins-hand.svg';
import { ReactComponent as MessageIcon } from 'assets/dashboard/finances/message.svg';
import { ReactComponent as WaiveIcon } from 'assets/dashboard/finances/waive.svg';
import { ReactComponent as ReminderIcon } from 'assets/dashboard/finances/bell.svg';
import { SearchClients } from './SearchClients';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { PATIENTS_RELATED } from 'pages/PatientListPage';
import { FormProvider, useForm } from 'react-hook-form';
import { RefundModal } from 'pages/AppointmentPage/RefundModal';
import { chipColor } from 'pages/PatientConsultations/ApptCardConsultation';
import useRefundAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useRefundAppointemnt';
import useEditSession from '@eggmed/graphql-client/operations/appointmentsOperations/useEditSession';
import Tabs from 'components/ui/Tabs';
import DateRangeInput from 'components/ui/Inputs/DateRangeInput';
import { ReactComponent as TransactionArrow } from 'assets/TransactionArrow.svg';
import SuperBillModal from './SuperBillModal';
import useGenerateSuperbill, {
  useDownloadSuperbill,
  useUpdatesuperbill,
} from '@eggmed/graphql-client/operations/appointmentsOperations/useGenerateSuperBill';
import SuperbillPreview from './superbill/SuperbillPreviewModal';
import Insurance from 'pages/PatientSettingsPage/Insurance';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import useDeleteSuperbill from '@eggmed/graphql-client/operations/appointmentsOperations/useDeleteSuperbill';
import TrashIcon from 'assets/TrashIcon';
import Steps from 'pages/PatientActivities/commun/Steps';
import ChangeBill from './superbill/ChangeBill';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';
import { convertObjectsFormatStaffs } from 'pages/SchedulePage/AddEventPage/utils';
import useCheckPermission from 'contexts/PermissionContext';
import useAddCheckPermission from 'contexts/AddPermissionOntext';
import FreeTag from 'components/ui/FreeTag';

interface DoctorPatientInfo {
  firstname: string;
  lastname: string;
  phone?: string;
  address?: string;
  suite?: string;
  city?: string;
  zipcode?: string;
  country?: string;
  state?: string;
}

interface IAppointement {
  patient: {
    firstname: string;
    middlename: string;
    lastname: string;
    picture: string;
    _id: string;
  };
  startDate: string;
  endDate: string;
  sessionType: {
    duration: string;
    session: string;
    rate: string;
    __typename?: string;
  };
  isPaid: string;
  isPrepay: boolean;
  _id: string;
  doctor: {
    timeZone: string;
  };
  invoice?: {
    invoiceRef: number;
    doctorInfo: DoctorPatientInfo;
    patientInfo: DoctorPatientInfo;
    amountPaid: number;
    issueDate: string;
    dueDate: string;
    invoicePdf?: string;
  };
  superbill: {
    ref: number;
  };
}
export enum Payment {
  paid = 'paid',
  overdue = 'overdue',
  pending = 'pending',
  waived = 'waived',
  refunded = 'refunded',
}
export enum Period {
  week = 'Week',
  month = 'Month',
  day = 'Day',
  year = 'Year',
}
const FinancePage = ({
  isAll,
  patientId,
}: {
  isAll?: boolean;
  patientId?: string;
}) => {
  const {
    createSuperbill,
    loading: loadingBillMutation,
    error: errorBill,
  } = useGenerateSuperbill();

  const {
    generateSuperBill,
    loading: loadingDownload,
    error: errorDownload,
  } = useDownloadSuperbill();
  const { isEnabled } = useAddCheckPermission('Finances');
  async function handleDownload(data) {
    if (step === 0) {
      setDownloadStarted(true);
      const link = await generateSuperBill({
        variables: {
          id: currentRow?.superbill?._id,
        },
      });
      window.open(`${link?.data?.generateSuperBill?.superBillPdf}`, '_blank');
      setTimeout(() => {
        handleCloseBillModal();
        setDownloadStarted(false);
      }, 2000);
    } else {
      const { sessions, ...rest } = data || {};
      await updateSuperBill({
        variables: {
          id: currentRow?.superbill?._id,
          superbillInput: rest,
        },
      });
      setStep(0);
    }
  }

  const { updateSuperBill, loading: loadingUpdate } = useUpdatesuperbill();
  const { handleRefund, loadingRefund } = useRefundAppointment();
  const { updateSession } = useEditSession();
  const [invoiceTitle, setInvoiceTitle] = useState('Create invoice');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = useState<any>();
  const [openBill, setOpenBill] = React.useState(false);
  function handleCloseBill() {
    setOpenBill(false);
    setDownloadStarted(false);
  }
  function handleOpenBill() {
    if (step !== 0) {
      setStep(0);
    }
    setOpenBill(true);
  }
  const [billInfo, setBillInfo] = React.useState({
    patient: null,
    insuranceRelated: null,
  });
  const methods = useForm({
    defaultValues: {},
  });
  const methodView = useForm({ defaultValues: {} });
  React.useEffect(() => {
    const obj = currentRow?.superbill?.editableBill
      ? currentRow?.superbill?.editableBill
      : currentRow?.superbill?.insuranceRelated?.relationShipType === 'Self'
      ? currentRow?.superbill?.patient
      : currentRow?.superbill?.insuranceRelated;
    methodView.reset({
      ...obj,
    });
  }, [
    methodView.reset,
    currentRow?.superbill?.insuranceRelated,
    currentRow?.superbill?.patient,
    currentRow?.superbill?.editableBill,
  ]);
  const { isEnabled: isEnabledFinance, loading: loadingFinance } =
    useCheckPermission('Finances', 'edit', currentRow?._id);
  const { isEnabled: isEnabledDeleteFinance } = useCheckPermission(
    'Finances',
    'delete',
    currentRow?._id
  );
  React.useEffect(() => {
    if (billInfo?.patient || billInfo?.insuranceRelated) {
      const obj =
        billInfo?.insuranceRelated?.relationShipType === 'Self'
          ? billInfo.patient
          : billInfo.insuranceRelated;
      methods.reset({
        ...obj,
      });
    }
  }, [methods.reset, billInfo.insuranceRelated, billInfo.patient]);

  const location = useLocation<{
    Time: string;
  }>();
  React.useEffect(() => {
    if (location.state?.Time) {
      setTime(location.state?.Time);
      setName(capitalizeFirstLetter(location.state?.Time.toLowerCase()));
    }
  }, [location.state]);
  const { triggerSnack } = useSnackbar();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  React.useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Finances' });
  }, [headerTitleDispatcher]);
  const classes = useStyles();
  const { handleOpenCreateNewThreadModal } = useInobx();
  const [name, setName] = React.useState<string>(Period.week);
  const [Time, setTime] = React.useState<string>(Period.week.toUpperCase());
  const { t } = useTranslation();
  const [menuName, setMenuName] = React.useState(t('Status'));
  const [menuValue, setMenuValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openWaive, setOpenWaive] = React.useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openInvoicePreview, setOpenInvoicePreview] = useState(false);
  const [openSuperBill, setOpenSuperBill] = React.useState(false);
  const { data: datas } = useQuery(TEAM_MEMBERS, {
    variables: { role: '', status: '' },
  });
  const users = convertObjectsFormatStaffs(datas?.teamSettings, '');
  const { user } = useAuth();
  function handleOpenBillModal() {
    setStep(0);
    setOpenSuperBill(true);
  }
  function handleCloseBillModal() {
    setOpenSuperBill(false);
  }

  const [switchStates, setSwitchStates] = React.useState<{
    [key: string]: boolean;
  }>({});
  const { data: patientsRelated } = useQuery(PATIENTS_RELATED);

  const handleSwitchChange = (id: string, value: boolean) => {
    const localValue = value === null ? false : value;
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: localValue,
    }));
  };

  function handleCloseInvoice() {
    setOpenInvoice(false);
  }
  function handleCloseInvoicePreview() {
    setOpenInvoicePreview(false);
  }
  function handleCloseWaive() {
    setOpenWaive(false);
  }
  function handleClose() {
    setOpen(false);
  }
  const { data, error, currentPage, handleChangePage, loadingData } =
    useProvider();
  const { deleteSuperBill, loading: loadingDelete } = useDeleteSuperbill();

  async function handleDeleteSuperbill() {
    await deleteSuperBill({
      variables: {
        id: currentRow?.superbill?._id,
      },
    });
    triggerSnack('Superbill deleted successfully');
    setOpenDelete(false);
  }
  const today = new Date();
  const [selectedClientID, setSelectedClientID] = React.useState(patientId);
  const [range, setRange] = React.useState({
    startDate: new Date(new Date().setDate(today.getDate() - 30)),
    endDate: today,
  });
  const [values, setValues] = React.useState([]);
  const [getFinances, { data: finances, loading, refetch }] =
    useLazyQuery(GET_FINANCES);
  React.useEffect(() => {
    if (range.endDate) {
      getFinances({
        variables: {
          limit: '5',
          page: `${currentPage}`,
          filter: menuValue,
          patient: isAll ? '' : patientId,
          searchValue: selectedClientID,
          start: range.startDate,
          end: range.endDate,
          doctors:
            values.length > 0 ? values?.map((el) => el.doctorId) : undefined,
        },
      });
    }
  }, [
    range.endDate,
    currentPage,
    menuValue,
    isAll,
    patientId,
    selectedClientID,
    values,
  ]);
  const [selectedClient, setSelectedClient] = React.useState('');

  const onChange = (dates) => {
    const [start, end] = dates;
    setRange({
      startDate: start,
      endDate: end,
    });
  };
  const [step, setStep] = React.useState(0);
  function handleEditStep() {
    setStep((currentStep) => currentStep + 1);
  }

  const [formData, setFormData] = React.useState({});
  async function onSubmitBill(data) {
    setFormData(data);
    if (step === 0) {
      const sessions = data?.sessions;
      const result = Object.entries(sessions)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      const link = await createSuperbill({
        variables: {
          sessions: result,
          patient: selectedClientID,
          startDate: range?.startDate,
          endDate: range?.endDate,
        },
      });
      setCurrentRow({ superbill: { _id: link?.data?.createSuperbill?._id } });
      setBillInfo({
        patient: link?.data?.createSuperbill?.patient,
        insuranceRelated: link?.data?.createSuperbill?.insuranceRelated,
      });
      setStep(1);
    }
    if (step === 1) {
      setDownloadStarted(true);
      const link = await generateSuperBill({
        variables: {
          id: currentRow?.superbill?._id,
        },
      });
      window.open(`${link?.data?.generateSuperBill?.superBillPdf}`, '_blank');
      setTimeout(() => {
        handleCloseBill();
      }, 2000);
    }
    if (step === 2) {
      const { sessions, ...rest } = data || {};
      await updateSuperBill({
        variables: {
          id: currentRow?.superbill?._id,
          superbillInput: rest,
        },
      });
      setStep(1);
    }
  }
  const [downloadStarted, setDownloadStarted] = useState(false);

  const { data: sessionNotBilled, loading: loadingBill } = useQuery(
    SESSION_BY_PERIOD_BILL,
    {
      variables: {
        patient: selectedClientID,
        startDate: range?.startDate,
        endDate: range?.endDate,
      },
    }
  );
  const refundForms = useForm();
  const [toggled, setToggle] = React.useState(false);
  const [openRefund, setOpenRefund] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  function handleChangeToggle(e) {
    setToggle((togl) => !togl);
  }

  function handleCloseRefund() {
    setOpenRefund(false);
    setToggle(false);
  }

  React.useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data, refetch]);
  const [id, setId] = React.useState('');
  const [row, setRow] = React.useState<{
    patient: {
      firstname: string;
      lastname: string;
      middlename: string;
      _id: string;
      email: string;
      picture: string;
    };
    startDate?: string;
    isPaid?: string;
  }>({
    patient: {
      firstname: '',
      lastname: '',
      middlename: '',
      _id: '',
      email: '',
      picture: '',
    },
  });
  function handleChange(e, row) {
    e.stopPropagation();
    setId(row?._id);
    setRow(row);
  }
  const { firstname, lastname, middlename, _id, email, picture } =
    row?.patient || {};
  const { startDate } = row || {};
  const subject = `${dayjs(startDate).format(
    'MMMM DD'
  )}th appointment with  ${capitalizeFirstLetter(
    firstname
  )} ${capitalizeFirstLetter(lastname)}`;
  const tabsBar = isAll
    ? ['Payment history']
    : ['Payment history', 'Insurance info'];

  const columns: IColumn<IAppointement>[] = [
    {
      id: 'firstname',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="client">Client</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 190,
      format: (value, row) => {
        const { firstname, middlename, lastname, picture, _id } =
          row?.patient || {};
        const avatarPicture = picture?.split('/')[3];
        return (
          <Box display="flex" alignItems="center" gridGap="0.5rem" ml={-1}>
            {/* <Checked
              checkedValue={id === row?._id}
              handleChange={(e) => handleChange(e, row)}
              onClick={(e) => e.stopPropagation()}
              color="#0265DC"
            /> */}
            <Box
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer' }}
            >
              <Avatar
                alt="Patient picture"
                src={avatarPicture !== 'undefined' ? picture : ''}
              >
                {`${firstname && firstname[0].toUpperCase()}${
                  lastname && lastname[0].toUpperCase()
                }`}
              </Avatar>
              <Box className={classes.rowText} pl={2}>{`${firstname} ${
                middlename || ''
              } ${lastname}`}</Box>
            </Box>
          </Box>
        );
      },
    },
    {
      id: 'startDate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="date">Date</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 210,
      format: (value, { doctor }) => {
        return (
          <Typography className={classes.rowText}>
            {dayjs(value).format('dddd, MMMM D, hh:mm A')}
          </Typography>
        );
      },
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="service">Service</Text>
        </Box>
      ),
      minWidth: 190,
      align: 'left',
      format: (value) =>
        value && (
          <Typography className={classes.rowText}>
            {value?.duration} min {value?.session}
          </Typography>
        ),
    },
    {
      id: 'isPaid',
      label: <Box fontSize="1rem">{t('Payment status')}</Box>,
      minWidth: 190,
      align: 'left',
      format: (value, { sessionType }) =>
        value === Payment.paid ? (
          <Text i18nKey="paid" className={classes.paid}>
            Paid
          </Text>
        ) : value === Payment.overdue ? (
          <Text i18nKey="overdue" className={classes.overdue}>
            Overdue
          </Text>
        ) : value === Payment.waived ? (
          <Text i18nKey="waived" className={classes.waived}>
            Waived
          </Text>
        ) : value === Payment.refunded ? (
          <Text i18nKey="refunded" className={classes.refunded}>
            Refunded
          </Text>
        ) : parseInt(sessionType?.rate) === 0 ? (
          <Text i18nKey="Not applicable" className={classes.free}>
            Not applicable
          </Text>
        ) : (
          <Text i18nKey="pending" className={classes.pending}>
            Pending
          </Text>
        ),
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="amount">Amount</Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value, { sessionType }) =>
        value &&
        (parseInt(sessionType?.rate) === 0 ? (
          <FreeTag />
        ) : (
          <Typography className={classes.rowText}>US${value?.rate}</Typography>
        )),
    },
    {
      id: 'invoice',
      label: <></>,
      minWidth: 100,
      align: 'left',
      format: (value, current) => (
        <Box className={classes.lastCol}>
          {current?.superbill?.ref && (
            <Typography
              className={classes.invoiceText}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentRow(current);
                handleOpenBillModal();
              }}
            >{`SPB #${current?.superbill?.ref}`}</Typography>
          )}
          {!current?.superbill?.ref && current?.invoice?.invoiceRef && (
            <Typography
              className={classes.invoiceText}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentRow(current);
                setOpenInvoicePreview(true);
              }}
            >{`INV #${current?.invoice?.invoiceRef}`}</Typography>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth="40px"
            minHeight="40px"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
              setCurrentRow(current);
              handleChange(e, current);
            }}
          >
            <DotsIcon className={classes.dots} />
          </Box>
        </Box>
      ),
    },
  ];
  const columnsTable = isAll
    ? columns
    : columns?.filter((el) => el?.id !== 'firstname');

  const history = useHistory();

  const timezone =
    finances?.finances?.result &&
    finances?.finances?.result[0]?.doctor?.timeZone;
  const filteredAppointments = transformArrayTimeZone(
    finances?.finances?.result,
    timezone
  );
  const { setReminder } = useSetReminder();
  const { waivePayment } = useWaivePayment();
  async function handleReminder() {
    await setReminder({
      variables: {
        appointmentId: id,
      },
    });
    handleClose();
  }
  async function handleWaive() {
    await waivePayment({
      variables: {
        appointmentId: id,
      },
    });
    handleCloseWaive();
  }

  const MenuElementsDataOverduePending = [
    {
      cy: 'waive',
      avatar: <WaiveIcon />,
      name: t('Waive payment'),
      onClick: isEnabledFinance
        ? () => {
            setOpenWaive(true);
            handleCloseMenu();
          }
        : undefined,
      isDisabled: !isEnabledFinance,
    },
    {
      cy: 'reminder',
      avatar: <ReminderIcon />,
      name: t('Send reminder'),
      onClick: isEnabledFinance
        ? () => {
            setOpen(true);
            handleCloseMenu();
          }
        : undefined,
      isDisabled: !isEnabledFinance,
    },
    {
      cy: 'message',
      avatar: <MessageIcon />,
      name: t('Message'),
      onClick: () => {
        handleOpenCreateNewThreadModal();
        handleCloseMenu();
      },
    },
    currentRow?.invoice?.invoiceRef && {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: t('View invoice'),
      onClick: () => {
        setOpenInvoicePreview(true);
        handleCloseMenu();
      },
    },
    currentRow?.superbill?._id && {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: 'View superbill',
      onClick: () => {
        handleOpenBillModal();
        handleCloseMenu();
      },
    },
    currentRow?.superbill?._id && {
      cy: 'view-invoice',
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Delete superbill',
      isRed: true,
      onClick: isEnabledDeleteFinance
        ? () => {
            setOpenDelete(true);
            handleCloseMenu();
          }
        : undefined,
      isDisabled: !isEnabledDeleteFinance,
    },
  ].filter(Boolean);

  const MenuElementsDataWaivedRefunded = [
    {
      cy: 'message',
      avatar: <MessageIcon />,
      name: t('Message'),
      onClick: () => {
        handleOpenCreateNewThreadModal();
        handleCloseMenu();
      },
    },
    currentRow?.invoice?.invoiceRef && {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: t('View invoice'),
      onClick: () => {
        setOpenInvoicePreview(true);
        handleCloseMenu();
      },
    },
    currentRow?.superbill?._id && {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: 'View superbill',
      onClick: () => {
        handleOpenBillModal();
        handleCloseMenu();
      },
    },
    currentRow?.superbill?._id && {
      cy: 'view-invoice',
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Delete superbill',
      isRed: true,
      onClick: isEnabledDeleteFinance
        ? () => {
            setOpenDelete(true);
            handleCloseMenu();
          }
        : undefined,
      isDisabled: !isEnabledDeleteFinance,
    },
  ].filter(Boolean);

  const MenuElementsDataPaid = [
    {
      cy: 'refund',
      avatar: <PaymentIcon />,
      name: t('Refund'),
      onClick: isEnabledFinance
        ? () => {
            setOpenRefund(true);
            handleCloseMenu();
          }
        : undefined,
      isDisabled: !isEnabledFinance,
    },
    {
      cy: 'message',
      avatar: <MessageIcon />,
      name: t('Message'),
      onClick: () => {
        handleOpenCreateNewThreadModal();
        handleCloseMenu();
      },
    },
    currentRow?.invoice?.invoiceRef && {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: t('View invoice'),
      onClick: () => {
        setOpenInvoicePreview(true);
        handleCloseMenu();
      },
    },
    currentRow?.superbill?._id && {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: 'View superbill',
      onClick: () => {
        handleOpenBillModal();
        handleCloseMenu();
      },
    },
    currentRow?.superbill?._id && {
      cy: 'view-invoice',
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Delete superbill',
      isRed: true,
      onClick: isEnabledDeleteFinance
        ? () => {
            setOpenDelete(true);
            handleCloseMenu();
          }
        : undefined,
      isDisabled: !isEnabledDeleteFinance,
    },
  ].filter(Boolean);

  const MenuSelector = {
    [Payment.paid]: MenuElementsDataPaid,
    [Payment.overdue]: MenuElementsDataOverduePending,
    [Payment.pending]: MenuElementsDataOverduePending,
    [Payment.waived]: MenuElementsDataWaivedRefunded,
    [Payment.refunded]: MenuElementsDataWaivedRefunded,
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const refundColumns = [
    {
      id: 'startDate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="date">Date</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 170,
      format: (value) => {
        return (
          <Typography className={classes.textRow}>
            {dayjs(value).format('MMM D, YYYY')}
          </Typography>
        );
      },
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="fees">Fees</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 170,
      format: (value) => {
        return (
          <Typography className={classes.textRow}>{value?.rate} USD</Typography>
        );
      },
    },
    {
      id: 'isPaid',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="paymentStatus">Payment status</Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value) => {
        return (
          <Box
            style={{
              width: '50%',
              borderRadius: '16px',
              background: chipColor[value]?.background,
              border: chipColor[value]?.border,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px 8px',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                color: chipColor[value]?.color,
                textTransform: 'capitalize',
              }}
            >
              {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'sessionType',
      label: <Box fontSize="1rem">SessionType</Box>,
      minWidth: 170,
      align: 'left',
      format: (value) => (
        <Typography className={classes.textRow}>{value?.session}</Typography>
      ),
    },
  ];

  async function onRefund(data) {
    const { paymentIntentId } = currentRow || {};

    if (data?.refund) {
      await handleRefund(paymentIntentId as string, Number(data?.refund) * 100);
      refetch();
    }

    handleCloseRefund();
  }
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchor(null);
  };
  const MenuElementsData = [
    {
      name: t('Invoice'),
      avatar: null,
      onClick: () => {
        setOpenInvoice(true);
        setAnchor(null);
      },
    },
    {
      name: t('Superbill'),
      avatar: null,
      onClick: () => {
        handleOpenBill();
        setAnchor(null);
      },
    },
  ];
  return (
    <Box mt={isAll ? 2 : 0} style={{ backgroundColor: 'white' }} p={2}>
      {isAll && (
        <FinancesHeader
          dataFinance={data}
          loading={loadingData}
          isOnFinancePage
          time={Time}
          setTime={setTime}
        />
      )}
      <Box
        display="flex"
        mt={isAll ? 6 : 2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <TransactionArrow style={{ marginRight: '8px' }} />
          <Typography className={classes.transactionText}>
            Transactions
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gridGap={'0.5rem'}>
          <Skeleton loading={loading}>
            <Button
              className={classes.btnSettings}
              onClick={() => history.push('/doctor/admin?tab=13')}
            >
              <SettingsIcon />
              <Text i18nKey="paymentSettings" style={{ fontWeight: 500 }}>
                Payment settings
              </Text>
            </Button>
          </Skeleton>
          <Skeleton loading={loading}>
            <Tooltip
              title={
                !isEnabled
                  ? 'This action is disabled because your role does not have the required permissions'
                  : ''
              }
              placement="bottom-end"
            >
              <CustomButton
                className={classes.addBtn}
                style={{
                  opacity: isEnabled ? 1 : 0.4,
                }}
                buttonType="reset"
                onClick={isEnabled ? handleClick : undefined}
              >
                <AddIcon />
                <Text i18nKey="create" style={{ fontWeight: 500 }}>
                  Create
                </Text>
              </CustomButton>
            </Tooltip>
          </Skeleton>
        </Box>
      </Box>
      <Tabs
        tabsBar={tabsBar}
        tabsMode="form"
        classNameTab={classes.tabForm}
        tabClassHeader={classes.tabHeader}
        componetAlignedRightWithTabs={
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row-reverse"
            position={'absolute'}
            right={0}
            top={0}
            mt={-0.5}
          >
            <Skeleton loading={loading}>
              <Box ml={'0.2rem'}>
                <DateRangeInput range={range} onChange={onChange} />
              </Box>
            </Skeleton>
            {isAll && (
              <Skeleton loading={loading}>
                <SearchClients
                  options={convertObjectsFormat(
                    patientsRelated?.patientsRelated,
                    ''
                  )}
                  placeholder="Select a client"
                  value={selectedClient}
                  handleChangePage={() => {}}
                  setValue={setSelectedClient}
                  setSearchQuery={setSelectedClientID}
                  filterOptions={(options, { inputValue }) => {
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter(
                      (option) =>
                        option.email
                          .toLowerCase()
                          .includes(inputValueLowerCase) ||
                        option.name.toLowerCase().includes(inputValueLowerCase)
                    );
                  }}
                />
              </Skeleton>
            )}
            {['Admin', 'owner']?.includes(user?.role) && users?.length > 0 && (
              <Box ml={0.2} width="20rem" mr={0.5}>
                <Symptoms
                  img={true}
                  type=""
                  disableCustomFilter={true}
                  options={users}
                  values={values}
                  setValues={setValues}
                  hasErrors={false}
                  placeholder="Select providers"
                />
              </Box>
            )}
            <Skeleton loading={loading}>
              <Box mr={'0.2rem'}>
                <SelectMenu
                  menuName={menuName}
                  setMenuName={setMenuName}
                  setMenuValue={setMenuValue}
                />
              </Box>
            </Skeleton>
          </Box>
        }
        tabsContent={[
          <>
            <Box
              display="flex"
              // justifyContent="space-between"
              alignItems="center"
              width="100%"
              mt={4}
            >
              <Box display="flex" alignItems="center" gridGap={'0.5rem'}>
                {/* <Skeleton loading={loading}>
            <Button
              className={classes.btn}
              disabled={
                id == '' ||
                row?.isPaid === Payment.paid ||
                row?.isPaid === Payment.waived
              }
              onClick={() => setOpen(true)}
            >
              {id == '' ||
              row?.isPaid === Payment.paid ||
              row?.isPaid === Payment.waived ? (
                <ReminderDisabled className={classes.svgMarginRight} />
              ) : (
                <Reminder className={classes.svgMarginRight} />
              )}
              <Text i18nKey='sendReminder'>Send reminder</Text>
            </Button>
          </Skeleton>
          <Skeleton loading={loading}>
            <Button
              className={classes.btn}
              disabled={id == ''}
              onClick={() => id && handleOpenCreateNewThreadModal()}
            >
              {id == '' ? (
                <MessageDisabled className={classes.svgMarginRight} />
              ) : (
                <Message className={classes.svgMarginRight} />
              )}
              <Text i18nKey='message'>Message</Text>
            </Button>
          </Skeleton>
          <Skeleton loading={loading}>
            <Button
              className={classes.btn}
              disabled={id == '' || row?.isPaid === Payment.waived}
              onClick={() => setOpenWaive(true)}
            >
              {id == '' || row?.isPaid === Payment.waived ? (
                <WaiveDisabled className={classes.svgMarginRight} />
              ) : (
                <Waive className={classes.svgMarginRight} />
              )}
              <Text i18nKey='waivePayment'>Waive payment</Text>
            </Button>
          </Skeleton> */}
              </Box>
            </Box>
            <Box my={2}>
              <Table<any>
                loading={loading}
                page={currentPage}
                columns={columnsTable}
                limit={5}
                handlePagination={handleChangePage}
                data={filteredAppointments || []}
                pagination
                EmptyState={
                  <>
                    <Box py="15rem">
                      <EmptyState
                        icon={<EmptyPayment />}
                        title={t('No payment history yet')}
                        hasAction={false}
                      />
                    </Box>
                  </>
                }
                count={finances?.finances?.count}
                fullData={false}
                onRowClick={(row) =>
                  history.push(
                    `/doctor/patient/${row?.patient?._id}?event=${row?._id}&tab=2`
                  )
                }
              />
            </Box>
          </>,
          <Box mt={4}>
            <Insurance patientId={patientId} />
          </Box>,
        ]}
      />

      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        handleMenuClose={handleCloseMenu}
        MenuElements={MenuSelector[currentRow?.isPaid]}
      />
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchor)}
        handleMenuClose={handleMenuClose}
        MenuElements={MenuElementsData}
        className={classes.menu}
      />
      <BasicModal
        open={open}
        onClose={handleClose}
        isRegularModal
        title=""
        titlePadding="3rem"
        handleClose={handleClose}
        isIcon={false}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          px={8}
          pb={4}
        >
          <ReminderLogo />
          <Box>
            <Text
              i18nKey="Send Payment Reminder"
              className={classes.paymentTitle}
            >
              Send Payment Reminder
            </Text>
            <Text
              i18nKey="Are you sure you want to send reminders to"
              className={classes.paymentText}
            >
              Are you sure you want to send reminders to
            </Text>
            <Text
              i18nKey="complete payment process?"
              className={classes.paymentText}
            >
              complete payment process?
            </Text>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Button className={classes.btnC} onClick={handleClose}>
              <Text i18nKey="cancel">Cancel</Text>
            </Button>
            <Button className={classes.btnSC} onClick={() => handleReminder()}>
              <Text i18nKey="send">Send</Text>
            </Button>
          </Box>
        </Box>
      </BasicModal>

      <BasicModal
        open={openWaive}
        onClose={handleCloseWaive}
        isRegularModal
        title=""
        titlePadding="3rem"
        handleClose={handleCloseWaive}
        isIcon={false}
      >
        <WaiveModal
          handleCloseWaive={handleCloseWaive}
          handleWaive={handleWaive}
        />
      </BasicModal>
      <Modals
        subject={subject}
        contact={[{ firstname, lastname, middlename, _id, email, picture }]}
      />
      <BasicModal
        open={openInvoice}
        onClose={handleCloseInvoice}
        isRegularModal
        title={invoiceTitle}
        titlePadding="2rem"
        handleClose={handleCloseInvoice}
        isSlide
        divider
      >
        <CreateInvoiceFlow
          handleClose={handleCloseInvoice}
          setInvoiceTitle={setInvoiceTitle}
          page={currentPage}
          filter={menuValue}
          patient={isAll ? '' : patientId}
          refetch={refetch}
        />
      </BasicModal>
      <FormProvider {...methodView}>
        <BasicModal
          open={openSuperBill}
          onClose={handleCloseBillModal}
          isFromModal
          title={'Superbill preview'}
          titlePadding="2rem"
          handleClose={handleCloseBillModal}
          isSlide
          divider
          onSubmit={handleDownload}
          loading={loadingDownload || loadingUpdate}
          submitText={step === 0 ? 'Download' : 'Save'}
          {...(step === 0 && {
            handleEditStep: handleEditStep,
          })}
        >
          <Box
            py={1}
            sx={{
              width: {
                xs: '50vw',
                lg: '48vw',
                xl: '40vw',
              },
            }}
            height="100%"
          >
            <Steps
              step={step}
              handleSubmit={() => {}}
              handleClose={() => {}}
              steps={[
                <SuperbillPreview
                  currentRow={currentRow}
                  handleClose={handleCloseBillModal}
                  downloadStarted={downloadStarted}
                  setDownloadStarted={setDownloadStarted}
                  loadingBillMutation={loadingBillMutation || loadingDownload}
                  errorBill={errorDownload}
                  formData={formData}
                  onSubmitBill={onSubmitBill}
                />,
                <ChangeBill />,
              ]}
            />
          </Box>
        </BasicModal>
      </FormProvider>
      <BasicModal
        open={openInvoicePreview}
        onClose={handleCloseInvoicePreview}
        isRegularModal
        title={t('Invoice Preview')}
        titlePadding="2rem"
        handleClose={handleCloseInvoicePreview}
        isSlide
        divider
      >
        <InvoicePreview
          currentRow={currentRow}
          handleClose={handleCloseInvoicePreview}
        />
      </BasicModal>
      <FormProvider {...refundForms}>
        <BasicModal
          open={openRefund}
          onClose={handleCloseRefund}
          handleClose={handleCloseRefund}
          isFromModal
          isSlide
          divider
          title={t('Complete your session')}
          titlePadding="2rem"
          onSubmit={onRefund}
          loading={loadingRefund}
        >
          <RefundModal
            isLoading={false}
            appointment={currentRow}
            columns={refundColumns}
            toggled={toggled}
            handleChange={handleChangeToggle}
            refundForms={refundForms}
          />
        </BasicModal>
      </FormProvider>
      <FormProvider {...methods}>
        <BasicModal
          open={openBill}
          onClose={handleCloseBill}
          handleClose={handleCloseBill}
          isFromModal
          isSlide
          divider
          title={'New superbill'}
          titlePadding="2rem"
          onSubmit={onSubmitBill}
          loading={loadingBillMutation || loadingDownload || loadingUpdate}
          submitText={step === 0 ? 'Create' : step === 1 ? 'Download' : 'Save'}
          {...(step === 1 && {
            handleEditStep: handleEditStep,
          })}
        >
          <Box
            px={step === 0 && '2rem'}
            py={1}
            sx={{
              width: {
                xs: '50vw',
                lg: '48vw',
                xl: '40vw',
              },
            }}
            height="100%"
          >
            <Steps
              step={step}
              handleSubmit={() => {}}
              handleClose={() => {}}
              steps={[
                <SuperBillModal
                  sessions={transformArrayTimeZone(
                    sessionNotBilled?.sessionsByPeriodBills,
                    timezone
                  )}
                  loadingBill={loadingBill}
                  isFilled={!!selectedClientID}
                  range={range}
                  onChange={onChange}
                  data={patientsRelated?.patientsRelated}
                  selectedClient={selectedClient}
                  setSelectedClient={setSelectedClient}
                  setSelectedClientID={setSelectedClientID}
                />,
                <SuperbillPreview
                  currentRow={currentRow}
                  handleClose={handleCloseBillModal}
                  downloadStarted={downloadStarted}
                  setDownloadStarted={setDownloadStarted}
                  loadingBillMutation={loadingBillMutation || loadingDownload}
                  errorBill={errorDownload}
                  formData={formData}
                  onSubmitBill={onSubmitBill}
                />,
                <ChangeBill />,
              ]}
            />
          </Box>
        </BasicModal>
      </FormProvider>
      <BasicModal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        isRegularModal
        handleClose={() => setOpenDelete(false)}
      >
        <DeleteModalContent
          text="superbill"
          handleClose={() => setOpenDelete(false)}
          handleDelete={() => handleDeleteSuperbill()}
          p={4}
          loading={loadingDelete}
        />
      </BasicModal>
    </Box>
  );
};

export default FinancePage;

export const SESSION_BY_PERIOD_BILL = gql`
  query sessionsByPeriodBills($patient: ID, $startDate: Date, $endDate: Date) {
    sessionsByPeriodBills(
      patient: $patient
      startDate: $startDate
      endDate: $endDate
    ) {
      _id
      startDate
      endDate
      locationLink
      sessionType {
        session
        rate
        duration
        currency
        color
        location
        isPrepayment
      }
      isPaid
    }
  }
`;
