import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hoverIcon: {
    opacity: 0,
    transition: 'opacity 0.3s',
    marginRight: '2px',
  },
  session: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F5FBFF',
    },
    '&:hover $hoverIcon': {
      opacity: 1,
    },
  },
  boxWrapper: {
    paddingBlock: '1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F5FBFF',
    },
    '&:hover $hoverIcon': {
      opacity: 1,
    },
  },
  boxWhite: {
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px solid #EAECF0',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
  actionIconRadius: {
    borderRadius: '10px',
  },
  managePayout: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  alertMsg: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#182230',
    fontFamily: 'Inter, sans-serif',
  },
  payoutAlert: {
    backgroundColor: '#FFFBFA',
    borderLeft: '5px solid #B42318',
  },
  emptyUpcoming: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'relative',

    '& > svg': {
      zIndex: 3,
    },
  },
  bgImg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  emptyTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    color: '#101828',
    marginTop: theme.spacing(1),
    zIndex: 3,
    textAlign: 'center',
  },
  emptySecTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
    textAlign: 'center',
    marginTop: '8px',
    marginBottom: theme.spacing(2),
    zIndex: 3,
  },
  scheduleBtn: {
    fontFamily: 'Inter, sans-serif',
    textTransform: 'unset',
    backgroundColor: '#0265DC',
    color: '#fff',
    borderRadius: '8px',
    paddingInline: '16px',

    '&:hover': {
      backgroundColor: '#082CAF',
      color: '#fff',
    },
  },
  msgBtn: {
    fontFamily: 'Inter, sans-serif',
    textTransform: 'unset',
    backgroundColor: '#fff',
    color: '#344054',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    paddingInline: '16px',
  },
  actionItem: {
    display: 'flex',
  },
  descriptionAction: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#667085',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
  titleAction: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    color: '#344054',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  hover: {
    '&:hover': {
      background: '#F9FAFB',
    },
  },
  root: {
    paddingTop: '30px',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.only('lg')]: {
      paddingTop: '0px',
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '0px',
      overflowY: 'hidden',
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: '0px',
      overflowY: 'hidden',
      marginTop: theme.spacing(-8),
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '0px',
      overflowY: 'hidden',
      marginTop: theme.spacing(-12),
    },
  },
  emptyState: {
    position: 'relative',
    left: '-5.5rem',
    [theme.breakpoints.only('lg')]: {
      left: '1.5rem',
    },
    [theme.breakpoints.only('md')]: {
      left: '-3rem',
    },
    [theme.breakpoints.only('sm')]: {
      left: '2.5rem',
    },
    [theme.breakpoints.only('xs')]: {
      left: '-3rem',
    },
  },
  title: {
    fontSize: '1.25rem',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.black.dark,
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.2rem',
      marginLeft: theme.spacing(-0.5),
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '0.75rem',
      marginLeft: theme.spacing(-1.5),
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.6rem',
      marginLeft: theme.spacing(-1.5),
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.6rem',
      marginLeft: theme.spacing(-1.5),
    },
  },
  paragraph: {
    fontSize: '0.875rem',
    marginTop: theme.spacing(1),
    color: theme.palette.black.dark,
    [theme.breakpoints.only('lg')]: {
      fontSize: '0.87rem',
    },
  },
  paragraphCountry: {
    fontSize: '0.875rem',
    // marginTop: theme.spacing(1),
    color: '#BCC6D3',
    [theme.breakpoints.only('lg')]: {
      fontSize: '0.87rem',
      marginLeft: theme.spacing(-2),
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '0.7rem',
      marginLeft: theme.spacing(-3.5),
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.55rem',
      marginLeft: theme.spacing(-3.5),
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.55rem',
      marginLeft: theme.spacing(-3.5),
      marginTop: '-2px',
    },
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '10px',
    textTransform: 'capitalize',
    [theme.breakpoints.only('lg')]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    [theme.breakpoints.only('md')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    [theme.breakpoints.only('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
  nameBreak: {
    flexGrow: 1,
  },
  ageBreak: {
    flexGrow: 1,
  },
  consultation: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    fill: 'solid',
    flexGrow: 1,
    color: theme.palette.black.dark,
    [theme.breakpoints.only('lg')]: {
      width: '100%',
    },
  },
  consultationMessage: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '18px',
    color: '#101828',
  },
  consultationTime: {
    fontWeight: 'bold',
    fontSize: '23px',
    lineHeight: '24px',
    letterSpacing: '0.12px',
    fill: 'solid',
    color: '#2DC76D',
    [theme.breakpoints.only('lg')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
    },
  },
  consultationDate: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    fill: 'solid',
    color: theme.palette.black.dark,
    [theme.breakpoints.only('lg')]: {
      fontSize: '17px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
      marginTop: '-5px',
    },
  },
  doctorName: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '#344054',
    fill: 'solid',
  },
  divider: {
    width: '100%',
  },
  speciality: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    lineHeight: '14px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    maxWidth: '9rem',
    fill: 'solid',
    color: '#A4A4A4',
  },
  lastMsgSent: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.5,
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    maxWidth: '95%',
    fill: 'solid',
    color: '#344054',
    overflow: 'hidden',
  },
  date: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
    fill: 'solid',
    color: '#475467',
  },
  img: {
    width: '100%',
    [theme.breakpoints.only('lg')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.only('sm')]: {
      marginBottom: theme.spacing(2),
      height: '200px',
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
      height: '200px',
    },
  },
  dividerWidth: {},
  dashboardHeader: {
    [theme.breakpoints.down('xl')]: {
      minHeight: '238px',
      height: '238px',
    },
  },
  imgText: {
    position: 'absolute',
    top: '25px',
    left: '5rem',
    [theme.breakpoints.only('lg')]: {
      position: 'absolute',
      top: '15px',
      left: '5rem',
    },
    [theme.breakpoints.only('md')]: {
      position: 'absolute',
      top: '20px',
      left: '3rem',
    },
    [theme.breakpoints.only('sm')]: {
      position: 'absolute',
      top: '65px',
      left: '3rem',
    },
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      top: '65px',
      left: '3rem',
    },
  },
  marginLg: {
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(-6),
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(-6),
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(-10),
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(-10),
      marginLeft: theme.spacing(0),
    },
  },
  upperSide: {
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(-6),
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(-9),
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(-12),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(-16),
    },
  },
  marginCommonSide: {
    marginLeft: theme.spacing(0),
    [theme.breakpoints.only('xl')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.only('lg')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(0),
    },
  },
  marginLgRightSide: {
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(-6),
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(-2),
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(0),
    },
  },
  flexBox: {
    [theme.breakpoints.only('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  unseen: {
    cursor: 'pointer',
    position: 'absolute',
    flexDirection: 'column',
    right: '2rem',
    [theme.breakpoints.only('lg')]: {
      position: 'absolute',
      right: '1rem',
    },
  },
  clock: {
    position: 'absolute',
    top: '10px',
    [theme.breakpoints.only('lg')]: {
      position: 'absolute',
      top: '5px',
      height: '70px',
    },
    [theme.breakpoints.only('md')]: {
      position: 'absolute',
      top: '5px',
      height: '70px',
    },
    [theme.breakpoints.only('sm')]: {
      position: 'absolute',
      top: '8px',
      height: '40px',
    },
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      top: '5px',
      height: '30px',
    },
  },
  clockText: {
    position: 'absolute',
    top: '35%',
    left: '14%',
    [theme.breakpoints.only('lg')]: {
      position: 'absolute',
      top: '25%',
      left: '16%',
    },
    [theme.breakpoints.only('md')]: {
      position: 'absolute',
      top: '25%',
      left: '16%',
    },
    [theme.breakpoints.only('sm')]: {
      position: 'absolute',
      top: '10%',
      left: '16%',
    },
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      top: '3%',
      left: '18%',
    },
  },
  select: {
    flexGlow: 1,
  },
  PatientPadding: {
    paddingInline: '1rem',
  },
  LastMessageBox: {
    backgroundColor: '#F8FAFB',
  },
  LastMessageBoxLine: {
    backgroundColor: '#F8FAFB',
    width: '100%',
    [theme.breakpoints.only('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(0),
      // marginLeft: theme.spacing(8),
      width: '330px',
      height: '253px',
    },
  },
  LastMessageBoxDoctor: {
    width: '100%',
    [theme.breakpoints.only('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.only('md')]: {
      // marginRight: theme.spacing(2),
      // height: '245px',
      width: '49%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  TeamBox: {
    backgroundColor: '#F8FAFB',
    width: '100%',
    [theme.breakpoints.only('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      height: '100%',
      width: '330px',
      // marginLeft: theme.spacing(3),
    },
  },
  pieBox: {
    backgroundColor: '#F8FAFB',
    height: '353px',
    width: '100%',
    [theme.breakpoints.only('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.only('md')]: {
      // marginRight: theme.spacing(0),
      width: '49%',
      // marginBottom: theme.spacing(4),
    },
  },
  task: {
    [theme.breakpoints.only('xl')]: {
      width: '100%',
    },
    [theme.breakpoints.only('lg')]: {
      width: '131%',
      marginLeft: '-20px',
    },
    [theme.breakpoints.only('md')]: {
      // width: '135%',
      // marginLeft: '-40px'
    },
    [theme.breakpoints.only('sm')]: {
      // width: '135%',
      // marginLeft: '-0px'
    },
  },
  boxLegend: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  legendPie: {
    position: 'absolute',
    right: '2rem',
    bottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.only('lg')]: {
      left: '2rem',
      bottom: '0rem',
    },
  },
  legendLine: {
    position: 'absolute',
    left: '2rem',
    bottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '30%',
    [theme.breakpoints.only('lg')]: {
      bottom: '0rem',
      width: '55%',
    },
    [theme.breakpoints.only('md')]: {
      bottom: '0rem',
      width: '55%',
    },
  },
  commonVar: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.6,
    padding: '3px 7px',
    borderRadius: '16px',
    width: 'fit-content',
    borderWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  upVar: {
    color: '#067647',
    backgroundColor: '#ECFDF3',
    borderColor: '#ABEFC6',
  },
  downVar: {
    color: '#B42318',
    backgroundColor: '#FEF3F2',
    borderColor: '#FECDCA',
  },
  stableVar: {
    color: '#B54708',
    backgroundColor: '#FFFAEB',
    borderColor: '#FEDF89',
  },
  loadingVar: {
    borderRadius: '16px',
  },
}));

export default useStyles;
