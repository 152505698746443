import { Box, Typography, Button, useTheme } from '@material-ui/core';
import { useProvider as useInobx } from 'pages/InboxPage/state/Provider';
import React, { useEffect, useRef, useState } from 'react';
import useGenerateAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useGenerateAppointment';
import { ReactComponent as PlusBook } from 'assets/PlusBook.svg';

import { useQuery } from '@apollo/client';
import {
  buildTzOptions,
  timezoneLabels,
} from '@eggmed/common/utils/timezoneOption';
import { useHandleMailsAction } from '@eggmed/graphql-client/operations/appointmentsOperations/useHandleMailsActions';
import FullCalendar from '@fullcalendar/react';
import { getTimeZones } from '@vvo/tzdb';
import { ReactComponent as CalendarUpcoming } from 'assets/CalendarUpcoming.svg';
import { ReactComponent as ArrowLeft } from 'assets/patient-profile/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/patient-profile/arrow-right.svg';
import clsx from 'clsx';
import Table from 'components/ui/Table';
import Tabs from 'components/ui/Tabs';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS } from 'pages/DashboardPage';
import Modals from 'pages/InboxPage/modals/index';
import { useStyles } from 'pages/PatientConsultations/styles';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import AddEventPage from './AddEventPage';
import ApptCard from './ApptCardPatient';

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { ReactComponent as EditableIcon } from 'assets/EditableIcon.svg';
import { ReactComponent as EmptySession } from 'assets/emptyState/session.svg';
import { ReactComponent as Eye } from 'assets/eye.svg';
import { ReactComponent as HideEye } from 'assets/HideEye.svg';
import { ReactComponent as InPersonConsultation } from 'assets/InPersonConsultation.svg';
import { ReactComponent as MessagingIcon } from 'assets/MessagingIcon.svg';
import { ReactComponent as OnlineConsultation } from 'assets/VideoConsultation.svg';
import Skeleton from 'components/ui/Skeleton';
import { IColumn } from 'components/ui/Table/types';
import EmptyState from 'pages/DashboardPage/EmptyState';
import {
  PAST_APPOINTMENTS_QUERY,
  UPCOMING_APPOINTMENTS_QUERY,
} from 'pages/PatientConsultations';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import { formatDates, formatTime, transformArrayTimeZone } from 'utils';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import FreeTag from 'components/ui/FreeTag';

export interface IAppointment {
  doctor: {
    _id: string;
    firstname: string;
    lastname: string;
    picture: string;
    middlename?: string;
    email?: string;
  };
  patient: { _id: string; firstname: string; lastname: string };
  title: string;
  _id: string;
  startDate: string;
  endDate: string;
  doctorGoing: string;
  patientGoing: string;
  invitation: { invitation_id: string };
}
const PatientSchedule = () => {
  const theme = useTheme();
  const isXlScreen = useMediaQuery('(min-width:1920px)');
  const isLargeScreen = useMediaQuery('(min-width:1390px)');
  const { t } = useTranslation();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Sessions') });
  }, [headerTitleDispatcher]);

  const classes = useStyles({});
  const calendarRef = useRef<FullCalendar | null>(null);
  const {
    currentDate,
    handleEditCurrentEvent,
    handleAddAppointment,
    currentEvent,
    handleUpdateAppointment,
    editMode,
    handleOpenAddModal,
    handleCloseEditMode,
    loadingAddAppointment,
    loadingEditAppointment,
    clickableEvent,
  } = useProvider();
  const [timeZone, setTimeZone] = useState('');

  const timeZones = getTimeZones();
  const tzOptions = buildTzOptions(timeZones);

  const [isClickable, setIsClickable] = React.useState(false);

  const { patient } = useAuth();

  const patientId = patient?._id;

  useEffect(() => {
    if (patient?.timeZone) {
      const index = timeZones.findIndex((tz) => tz.name === patient?.timeZone);
      setTimeZone(tzOptions[index]);
    }
  }, [patient, timezoneLabels]);

  useEffect(() => {
    calendarRef.current?.getApi().gotoDate(currentDate);
  }, [currentDate]);

  const [limit, setLimit] = React.useState(4);
  const [page, setPage] = useState<number>(1);

  const { loading, data } = useQuery(GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS, {
    variables: {
      filter: { limit, skip: (page - 1) * limit },
    },
  });

  const hasNext =
    page < Math.ceil(data?.doctorUpcomingAppointments?.count / limit);

  const increasePage = () => {
    setPage((s) => s + 1);
  };

  const decreasePage = () => {
    if (page > 1) setPage((s) => s - 1);
  };

  const { handleGenerate } = useGenerateAppointment();

  useHandleMailsAction({
    handleEditCurrentEvent,
    handleOpenAddModal,
    isDoctor: false,
  });
  const timezone =
    data?.doctorUpcomingAppointments?.result &&
    data?.doctorUpcomingAppointments?.result[0]?.patient?.timeZone;
  const patientUpcomingAppointment = transformArrayTimeZone(
    data?.doctorUpcomingAppointments?.result,
    timezone
  );
  const skeletonSessions = isXlScreen
    ? [1, 2, 3, 4]
    : isLargeScreen
    ? [1, 2, 3]
    : [1, 2];
  const [pastConsultationPage, setPastConsultationPage] = useState(1);
  const [upcomingConsultationPage, setUpcomingConsultationPage] = useState(1);
  const [limiting, setLimiting] = useState(10);
  const { data: upcomingAppointments, loading: nextLoading } = useQuery(
    UPCOMING_APPOINTMENTS_QUERY,
    {
      variables: {
        patientId,
        page: upcomingConsultationPage,
        limit: limiting,
      },
    }
  );
  const { data: pastAppointments, loading: pastLoading } = useQuery(
    PAST_APPOINTMENTS_QUERY,
    {
      variables: {
        patientId,
        page: pastConsultationPage,
        limit: limiting,
      },
    }
  );
  const { handleOpenCreateNewThreadModal } = useInobx();

  const [selectedRow, setSelectedRow] = useState(null);
  const { firstname, lastname, middlename, _id, email, picture } =
    selectedRow?.doctor || {};
  function handleMessage(row) {
    setSelectedRow(row);
    handleOpenCreateNewThreadModal();
  }
  const handlePagination = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
    setState: (e: number) => void
  ) => {
    setState(page);
  };
  useEffect(() => {
    if (isXlScreen) {
      setLimit(4);
    } else if (isLargeScreen) {
      setLimit(3);
    } else {
      setLimit(2);
    }
  }, [isLargeScreen, isXlScreen]);
  const columns: IColumn<any>[] = [
    {
      id: 'startDate',
      label: (
        <Box
          fontSize="1rem"
          style={{ fontSize: '14px', fontWeight: 500, color: '#475467' }}
        >
          <Text i18nKey="date">Date</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 70,
      format: (value, { _id }) => {
        return (
          <Typography
            style={{
              textDecoration: 'none',
              fontSize: '14px',
              fontWeight: 500,
              color: '#475467',
            }}
          >
            {formatDates(value)}
          </Typography>
        );
      },
    },
    {
      id: 'startDate',
      label: (
        <Box style={{ fontSize: '14px', fontWeight: 500, color: '#475467' }}>
          <Text i18nKey="time">Time</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 70,
      format: (value, { doctor }) => (
        <Typography
          style={{ fontSize: '14px', fontWeight: 500, color: '#475467' }}
        >
          {formatTime(value, doctor)}
        </Typography>
      ),
    },
    {
      id: 'sessionDuration',
      label: (
        <Box
          fontSize="1rem"
          style={{ fontSize: '14px', fontWeight: 500, color: '#475467' }}
        >
          <Text i18nKey="duration">Duration</Text>
        </Box>
      ),
      minWidth: 70,
      align: 'left',
      format: (value, { sessionType }) => (
        <Typography
          style={{ fontSize: '14px', fontWeight: 500, color: '#475467' }}
        >
          {sessionType?.duration} <Text i18nKey="minutes">minutes</Text>
        </Typography>
      ),
    },
    // {
    //   id: 'sessionType',
    //   label: <Box fontSize="1rem">Session type</Box>,
    //   minWidth: 100,
    //   align: 'left',
    //   format: (value, { sessionType }) => (
    //     <Typography>{sessionType?.session}</Typography>
    //   ),
    // },
    {
      id: 'locationLink',
      label: (
        <Box
          fontSize="1rem"
          style={{ fontSize: '14px', fontWeight: 500, color: '#475467' }}
        >
          <Text i18nKey="location">Location</Text>
        </Box>
      ),
      minWidth: 70,
      align: 'left',
      format: (value) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={70}
        >
          {value ? <OnlineConsultation /> : <InPersonConsultation />}
        </Box>
      ),
    },
    {
      id: 'Fees',
      label: (
        <Box
          fontSize="1rem"
          style={{ fontSize: '14px', fontWeight: 500, color: '#475467' }}
        >
          <Text i18nKey="fees">Fees</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 70,
      format: (value, { sessionType }) =>
        parseInt(sessionType.rate) === 0 ? (
          <FreeTag />
        ) : (
          <Typography
            style={{ fontSize: '14px', fontWeight: 500, color: '#475467' }}
          >
            {sessionType?.rate} {sessionType?.currency}
          </Typography>
        ),
    },
    {
      id: 'isPaid',
      label: (
        <Box
          fontSize="1rem"
          style={{ fontSize: '14px', fontWeight: 500, color: '#475467' }}
        >
          <Text i18nKey="paymentStatus">{t('Payment status')}</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 100,
      format: (value, { sessionType }) =>
        value === 'paid' ? (
          <Text i18nKey="paid" className={classes.paid}>
            Paid
          </Text>
        ) : value === 'overdue' ? (
          <Text i18nKey="overdue" className={classes.overdue}>
            Overdue
          </Text>
        ) : value === 'waived' ? (
          <Text i18nKey="waived" className={classes.waived}>
            Waived
          </Text>
        ) : value === 'refunded' ? (
          <Text i18nKey="Refunded" className={classes.refunded}>
            Refunded
          </Text>
        ) : parseInt(sessionType.rate) === 0 ? (
          <Text i18nKey="Not applicable" className={classes.freeSession}>
            Not applicable
          </Text>
        ) : (
          <Text i18nKey="pending" className={classes.pending}>
            Pending
          </Text>
        ),
    },
    {
      id: 'actions',
      label: <Box fontSize="1rem"></Box>,
      minWidth: 70,
      align: 'left',
      format: (value, row) => {
        return (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <HideEye style={{ marginRight: '15px' }} />
            <MessagingIcon
              style={{ marginRight: '15px', cursor: 'pointer' }}
              onClick={() => {
                handleMessage(row);
              }}
            />
            <EditableIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleEditCurrentEvent(row)}
            />
          </Box>
        );
      },
    },
  ];

  const pastColumns = [
    ...columns?.filter((column) => column.id !== 'actions'),
    {
      id: 'actions',
      label: <Box fontSize="1rem"></Box>,
      minWidth: 70,
      align: 'left',
      format: (value, row) => {
        return (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Eye
              style={{ marginRight: '15px', cursor: 'pointer' }}
              onClick={() => handleGenerate(row?._id)}
            />
            <MessagingIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleMessage(row)}
            />
          </Box>
        );
      },
    },
  ] as IColumn<IAppointment>[];
  return (
    <>
      <Box
        style={{ backgroundColor: 'white', borderRadius: '12px' }}
        px={4}
        py={4}
      >
        <Splide
          options={{
            gap: '0rem',
            perPage: 4,
            type: 'slide',
            perMove: 4,
            pagination: false,
            arrows: true,
            classes: {
              arrows: 'splide__arrows custom-arrows',
            },
            breakpoints: {
              1919: {
                perMove: 3.25,
                perPage: 3.25,
              },
              1536: {
                perMove: 3,
                perPage: 3,
              },
              1390: {
                perMove: 2,
                perPage: 2,
              },
              900: {
                perMove: 1,
                perPage: 1,
              },
            },
          }}
          hasTrack={false}
          aria-label="My Favorite Images"
        >
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <CalendarUpcoming style={{ marginRight: '10px' }} />
                <Text
                  i18nKey="upcomingSessions"
                  style={{
                    fontWeight: 600,
                    fontSize: '25px',
                    color: '#101828',
                  }}
                >
                  Upcoming sessions
                </Text>
                <div className="splide__arrows custom-arrows">
                  <button
                    className={clsx(
                      'splide__arrow splide__arrow--prev',
                      classes.arrowDisabled
                    )}
                    style={{ display: 'none' }}
                  ></button>
                  <button
                    className={clsx(
                      'splide__arrow splide__arrow--next',
                      classes.arrowDisabled
                    )}
                  ></button>
                </div>
                <div
                  className={clsx(
                    'splide__arrows custom-arrows',
                    classes.arrows
                  )}
                >
                  <button
                    className={clsx('splide__arrow', classes.arrow)}
                    onClick={decreasePage}
                    disabled={loading || page === 1}
                  >
                    <ArrowLeft />
                  </button>
                  <button
                    className={clsx('splide__arrow', classes.arrow)}
                    onClick={increasePage}
                    disabled={loading || !hasNext}
                  >
                    <ArrowRight />
                  </button>
                </div>
              </Box>
              <Button
                className={classes.buttonBook}
                onClick={() => handleOpenAddModal()}
              >
                <PlusBook style={{ marginRight: '5px' }} />
                <Text
                  style={{
                    color: '#0265DC',
                    fontWeight: 600,
                    fontSize: '16px',
                    textTransform: 'none',
                  }}
                  i18nKey="bookSession"
                >
                  Book session
                </Text>
              </Button>
            </Box>
          </Box>
          <Box mt={4}>
            <SplideTrack>
              {loading && (
                <Box display="flex" width="100%" style={{ gap: '1rem' }}>
                  {skeletonSessions.map(() => (
                    <SplideSlide>
                      <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        p="1rem"
                        border="1px solid #EAECF0"
                        borderRadius="12px"
                        height="140px"
                        flex="1"
                        className={classes.skeleton}
                      >
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          width="100%"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Skeleton loading={true} type="circle">
                              <Box
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  borderRadius: '100%',
                                }}
                              />
                            </Skeleton>
                            <Skeleton
                              loading={true}
                              type="text"
                              width="50px"
                              height="21px"
                            >
                              <Box
                                style={{
                                  width: '50px',
                                  height: '10px',
                                }}
                              />
                            </Skeleton>
                          </Box>
                          <Box display="flex" flexDirection="column" ml={2}>
                            <Skeleton
                              loading={true}
                              type="text"
                              width="100px"
                              height="21px"
                            >
                              <Box
                                style={{
                                  width: '100px',
                                  height: '21px',
                                  borderRadius: '8px',
                                }}
                              />
                            </Skeleton>
                            <Skeleton
                              loading={true}
                              type="text"
                              width="120px"
                              height="21px"
                            >
                              <Box
                                style={{
                                  width: '120px',
                                  height: '21px',
                                  borderRadius: '8px',
                                }}
                              />
                            </Skeleton>
                          </Box>
                        </Box>
                      </Box>
                    </SplideSlide>
                  ))}
                </Box>
              )}

              {patientUpcomingAppointment?.length === 0 && !loading && (
                <Box py="2rem" width="100%">
                  <EmptyState
                    icon={<EmptySession />}
                    title={t("No sessions yet. Let's get started.")}
                    text={t('to schedule your first session.')}
                    onClick={() => handleOpenAddModal()}
                    isText
                  />
                </Box>
              )}
              {patientUpcomingAppointment?.map((element) => (
                <SplideSlide>
                  <ApptCard event={element} loading={loading} />
                </SplideSlide>
              ))}
            </SplideTrack>
            <ul
              className={clsx(
                'splide__pagination splide__pagination--ltr',
                classes.paginationUl
              )}
              role="tablist"
            ></ul>
          </Box>
        </Splide>
      </Box>
      <Box
        my={3}
        style={{ backgroundColor: 'white', borderRadius: '12px' }}
        px={4}
        py={4}
      >
        <Box mb={4} display="flex" alignItems="center">
          <CalendarUpcoming style={{ marginRight: '10px' }} />

          <Text
            i18nKey="allSessions"
            style={{ fontWeight: 600, fontSize: '25px', color: '#101828' }}
          >
            All sessions
          </Text>
        </Box>
        <Tabs
          tabsBar={[t('Upcoming'), t('Past')]}
          tabsContent={[
            <Table<any>
              loading={nextLoading}
              page={upcomingConsultationPage}
              columns={columns}
              limit={limiting}
              fullData={false}
              count={upcomingAppointments?.patientUpcomingAppointments?.count}
              EmptyState={
                <Box py="5rem" width="100%">
                  <EmptyState
                    icon={<EmptySession />}
                    title={t(
                      'Here, you can review all your upcoming sessions and find out all the details.'
                    )}
                    hasAction={false}
                  />
                </Box>
              }
              handlePagination={(event, page) =>
                handlePagination(event, page, setUpcomingConsultationPage)
              }
              data={transformArrayTimeZone(
                upcomingAppointments?.patientUpcomingAppointments?.result || [],
                timezone
              )}
              pagination
            />,
            <Table<any>
              loading={pastLoading}
              page={pastConsultationPage}
              columns={pastColumns}
              limit={limiting}
              fullData={false}
              count={pastAppointments?.patientPastAppointments.count}
              EmptyState={
                <Box py="5rem" width="100%">
                  <EmptyState
                    icon={<EmptySession />}
                    title={t(
                      'Here, you can review all your past sessions and find out all the details.'
                    )}
                    hasAction={false}
                  />
                </Box>
              }
              handlePagination={(event, page) =>
                handlePagination(event, page, setPastConsultationPage)
              }
              data={transformArrayTimeZone(
                pastAppointments?.patientPastAppointments?.result || [],
                timezone
              )}
              pagination
            />,
          ]}
          tabsMode="simple"
        />
      </Box>{' '}
      <AddEventPage
        closeModal={handleCloseEditMode}
        editMode={editMode}
        patientId={patientId}
        eventData={currentEvent}
        addEvent={editMode ? handleUpdateAppointment : handleAddAppointment}
        loadingAppointment={
          editMode ? loadingEditAppointment : loadingAddAppointment
        }
        clickableEvent={clickableEvent}
        isClickable={isClickable}
        setIsClickable={setIsClickable}
      />
      <Modals
        contact={[
          {
            firstname,
            lastname,
            middlename,
            _id,
            email,
            picture,
          },
        ]}
        subject="Appointment"
        isPatient
      />
    </>
  );
};

export default PatientSchedule;
