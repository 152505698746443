import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  BoxProps,
  FormLabel,
  Chip,
  FormHelperText,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useTheme } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import SuccessButton from 'components/ui/Button/SuccesButton';
import {
  convertToRawAndStringify,
  editorStateFromStringifiedRaw,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { formatToFullDateWithoutTime } from 'utils';
import { Editor } from 'draft-js';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import useGetDoctors from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctors';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ScrollBar from 'components/ui/ScrollBar';
import Button from 'components/ui/Button';
import DeleteHandler from 'components/DeleteHandler';

import useAddTaskAttachment from '@eggmed/graphql-client/operations/tasksOperations/useAddTaskAttachment';
import { FormInput } from 'components/ui/Inputs/FormInput';
import EditableInput from './EditableInput';
import { useProvider } from '../state/Provider';
import { getTasks_tasks } from '../__generated__/getTasks';
import PriorityChip from '../components/PriorityChip';
import TaskInput from '../TaskAdd/TaskInput';
import EditableComponent from './isEditable';
import AssignedTo from 'components/ui/AssignedTo';
import CustomSelect, {
  CustomSelectInput,
  RegisteredSelect,
} from '../components/CustomSelect';
import AttachmentPreview from '../components/AttachmentPreview';
import AttachmentInput from '../components/AttachmentInput';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useStyles from '../TaskAdd/styles';
import EditButtons from 'components/EditButtons';
import { useToast } from 'hooks/useToast';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { IConvetArrayType } from 'pages/SchedulePage/AddEventPage/utils';
import { convertString } from '../List';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import DateChip from '../components/DateChip';
import StatusChip from '../components/StatusChip';
import UploadFilePreview from 'components/ui/UploadFileUi/UploadFilePreview';
import { ReactComponent as PdfIcon } from 'assets/icons/PdfIconFile.svg';
import { useSnackbar } from 'hooks/useSnackbar';
import AttachmentItemPreview from '../components/AttachmentItemPreview';
import { MAX_SIZE } from 'components/ui/UploadFileUi/AnimatedProgressBar/AnimatedProgressBar';
import { ReactComponent as DateIcon } from 'assets/patientActivities/inputs/date.svg';
import AttachmentInputFile from '../components/AttachementInputFile';
import { ReactComponent as Rename } from 'assets/icons/edit-03.svg';
import { ReactComponent as Download } from 'assets/icons/download-01.svg';
import { ReactComponent as Delete } from 'assets/icons/trash-01.svg';
interface ITaskViewProps extends getTasks_tasks, Omit<BoxProps, 'title'> {
  closeModal: () => void;
  changeTaskPosition: any;
  getList: (id: string, data: any) => any;
  taskList: any;
}

export default function TaskView({
  title,
  _id,
  description,
  status,
  dueDate,
  priority,
  attachments,
  createdAt,
  updatedAt,
  assignedTo,
  patients,
  closeModal,
  changeTaskPosition,
  getList,
  taskList,
  ...props
}: ITaskViewProps) {
  const { t } = useTranslation();
  const { triggerToast } = useToast();
  const { triggerSnack } = useSnackbar();
  const [isEdit, setIsEdit] = useState(true);
  const [currentFile, setCurrentFile] = useState();
  function handleGetStatusById(id) {
    return taskList?.find((list) =>
      list?.tasks?.some((task) => task._id === id)
    )?.name;
  }
  const {
    handleEditTask,
    handleDeleteTaskAttachment,
    handleDeleteTask,
    handleAddTaskAttachment,
    users,
    loadingUpdateTask,
    doctors,
    patients: dataPatient,
  } = useProvider();
  const { user } = useAuth();
  const classes = useStyles();
  const [values, setValues] = useState(convertObjectsFormat(assignedTo, ''));
  const [patientValues, setPatientValues] = useState(
    convertObjectsFormat(patients, 'Patients')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const getValuesRef = React.useRef<any>({
    title,
    description,
    status,
    dueDate: new Date(dueDate).toString(),
    priority,
    attachments,
  });
  const { register, control, errors, getValues, setValue, reset, watch } =
    useForm({
      defaultValues: {
        title,
        description,
        status: handleGetStatusById(_id),
        dueDate: new Date(dueDate).toString(),
        priority,
        attachments,
      },
    });

  const handleDownload = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    link.click();
  };
  const getMenuItems = (
    url: string,
    index: number
  ): {
    url: string;
    avatar: React.ReactNode;
    name: string;
    onClick: () => void;
  }[] => [
    /* {
      url,
      avatar: <Rename style={{ marginRight: '8px' }} />,
      name: 'Rename',
      onClick: () => console.log(`Rename file at ${url}`),
    },*/
    {
      url,
      avatar: <Download style={{ marginRight: '8px' }} />,
      name: 'Download',
      onClick: () => handleDownload(url, 'file.pdf'),
    },
    {
      url,
      avatar: <Delete style={{ marginRight: '8px' }} />,
      name: 'Delete',
      onClick: async () =>
        await handleDeleteTaskAttachment(_id, url, () =>
          setFiles([...files.slice(0, index), ...files.slice(index + 1)])
        ),
    },
  ];
  const sourceList = getList(_id, taskList);
  const commonProps = { register, control, errors, variant: 'filled' };
  const currentFormField = getValuesRef.current;
  function handleUpdate(close: boolean) {
    const formValues = getValues();
    getValuesRef.current = { ...getValuesRef.current, ...formValues };
    reset(getValuesRef.current);
    const editedData = { ...getValuesRef.current };
    delete editedData.attachments;
    const destinationList = taskList?.find(
      (el) => el.name === editedData.status
    );
    const { attachment, ...rest } = editedData || {};

    const updatedTask = {
      ...rest,
      description: editedData?.description,
      assignedTo: values?.map(({ _id }) => _id),
      patients: patientValues?.map(({ _id }) => _id),
    };
    handleEditTask(_id, updatedTask, close);
    if (destinationList?.name !== sourceList?.name)
      changeTaskPosition(
        destinationList?.tasks?.length,
        sourceList._id,
        destinationList._id,
        taskList,
        _id
      );
  }
  const handleSave = async () => {
    await handleUpdate(true);
    triggerSnack(t('tasks_updated_successfully'));
  };
  const theme = useTheme();
  useEffect(() => {
    setFiles(attachments);
  }, [attachments]);
  const isResponsive = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  return (
    <Box {...props} className={classes.responsiveMobile}>
      <ScrollBar className={classes.scroll}>
        <Box px="2rem">
          <Box>
            <TaskInput
              type="text"
              name="title"
              label={t('Title')}
              data-cy="title-input"
              {...commonProps}
            />
          </Box>
          <Box className={classes.equal}>
            <Box display="flex" mt={4}>
              <FormLabel className={classes.label}>
                <Text className={clsx(classes.label)} i18nKey="status">
                  Status
                </Text>
              </FormLabel>
            </Box>
            <RegisteredSelect
              style={{ marginTop: '10px', width: '100%' }}
              renderValue={(value: any) => (
                <StatusChip
                  data-cy="status-view"
                  style={{
                    cursor: 'pointer',
                    borderRadius: '8px',
                  }}
                  priority={convertString[value]}
                />
              )}
              name="status"
              renderOption={(value: any) => (
                <StatusChip
                  data-cy="status-option"
                  style={{
                    height: '24px',
                    cursor: 'pointer',
                    borderRadius: '8px',
                  }}
                  priority={convertString[value]}
                />
              )}
              options={['overdue', 'pending', 'completed']}
              control={control}
              register={register}
              onUpdate={() => handleUpdate(false)}
            />
            {errors && errors['status'] && (
              <FormHelperText className={classes.error}>
                <ErrorIcon className={classes.errorIcon} />
                {(errors as any)['status'].message}
              </FormHelperText>
            )}
          </Box>
          <Box className={classes.labelSpacingAssigned}>
            <Text
              i18nKey="assignedTo"
              className={clsx(classes.label, classes.spacingIcon)}
            >
              Assigned To
            </Text>
          </Box>
          <Box style={{ marginTop: '-6px' }}>
            <Symptoms
              type=""
              disableCustomFilter={true}
              options={users}
              values={values}
              setValues={(valuesHere) => {
                setValues(valuesHere);
                const editedData = { ...getValuesRef.current };
                delete editedData.attachments;
                const updatedTask = {
                  ...editedData,
                  assignedTo: valuesHere?.map(({ id }) => id),
                };
                handleEditTask(_id, updatedTask);
              }}
            />
            <Box>
              {values.length === 0 && (
                <FormHelperText className={classes.error}>
                  <ErrorIcon className={classes.errorIcon} />
                  {t('Assign to is a required field')}
                </FormHelperText>
              )}
            </Box>
          </Box>

          <Box className={classes.labelSpacingAssigned}>
            <Text
              i18nKey="relatedClients"
              className={clsx(classes.label, classes.spacingIcon)}
              style={{ marginBottom: '-5px' }}
            >
              Clients
            </Text>
          </Box>
          <Box style={{ marginTop: '-6px' }}>
            <Symptoms
              disableCustomFilter={true}
              options={convertObjectsFormat(dataPatient, 'Patients')}
              values={patientValues}
              setValues={(valuesHere) => {
                setPatientValues(valuesHere);
                const editedData = { ...getValuesRef.current };
                delete editedData.attachments;
                const updatedTask = {
                  ...editedData,
                  description: editedData.description,
                  patients: valuesHere?.map(({ id }) => id),
                };
                handleEditTask(_id, updatedTask);
              }}
            />
          </Box>
          <Box style={{ display: 'inline-flex', width: '100%', gap: '20px' }}>
            <Box width="50%">
              <TaskInput
                onChange={(date: any) => {
                  setValue('dueDate', date);
                  handleUpdate(false);
                }}
                type="date"
                label={t('Due date')}
                className={classes.inputDate}
                name="dueDate"
                customInput={
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    className={classes.inputWrapperCustom}
                  >
                    <DateChip
                      data-cy="date-view"
                      className={
                        watch()?.dueDate ? classes.dateChip : classes.noDate
                      }
                      label={
                        watch()?.dueDate &&
                        formatToFullDateWithoutTime(watch()?.dueDate)
                      }
                    />
                    <InputAdornment
                      style={{ marginLeft: 'auto' }}
                      position="end"
                    >
                      <IconButton aria-label="calendar">
                        <DateIcon />
                      </IconButton>
                    </InputAdornment>
                  </Box>
                }
                {...commonProps}
              />
            </Box>
            <Box width="50%" className={classes.container}>
              <Box className={classes.equal}>
                <Box display="flex" mt={4}>
                  <FormLabel className={clsx(classes.label)}>
                    <Text className={clsx(classes.label)} i18nKey="priority">
                      Priority
                    </Text>
                  </FormLabel>
                </Box>
                <RegisteredSelect
                  renderValue={(value: any) => (
                    <PriorityChip
                      data-cy="priority-view"
                      className={classes.chip}
                      priority={value}
                    />
                  )}
                  name="priority"
                  renderOption={(value: any) => (
                    <PriorityChip
                      data-cy="select-priority"
                      className={classes.chip}
                      priority={value}
                    />
                  )}
                  data-cy="select-priority"
                  onUpdate={handleUpdate}
                  options={['high', 'low', 'medium']}
                  control={control}
                  register={register}
                  style={{ marginTop: '10px', width: '100%' }}
                />
                {errors && errors['priority'] && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {(errors as any)['priority'].message}
                  </FormHelperText>
                )}
              </Box>
            </Box>
          </Box>
          <Box mt={0}>
            <TaskInput
              className={classes.draftjsInput}
              name="description"
              {...commonProps}
              type="area"
              multiline
              minRows={10}
              placeholder={t('placeholderDescription')}
              label="Description"
            />
            {errors && errors['description'] && (
              <Box>
                <FormHelperText className={classes.error}>
                  <ErrorIcon className={classes.errorIcon} />
                  {(errors as any)['description'].message}
                </FormHelperText>
              </Box>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel className={clsx(classes.label)}>
              <Text className={clsx(classes.label)} i18nKey="attachment(s)">
                Attachment(s)
              </Text>
            </FormLabel>
            <AttachmentInputFile
              data-cy="file-add"
              my={2}
              namePart1={t('ClickUpload')}
              namePart2={t('Drag&Drop')}
              max_size={t('MaxSize')}
              isActionable
              createdAt={new Date()}
              handleActionDragAndDrop={(event: any) => {
                const newFile = event?.dataTransfer?.files[0];
                setCurrentFile(event?.dataTransfer?.files[0]);
                event?.dataTransfer?.files[0].size < MAX_SIZE &&
                  handleAddTaskAttachment(_id, [newFile]);
              }}
              handleAction={(event: any) => {
                const newFile = event.target?.files[0];
                setCurrentFile(event.target?.files[0]);
                event?.target?.files[0].size < MAX_SIZE &&
                  handleAddTaskAttachment(_id, [newFile]);
              }}
            />
          </Box>
          {currentFile && (
            <UploadFilePreview
              file={currentFile}
              url={''}
              isEdit={false}
              setCurrentFile={setCurrentFile}
              icon={<PdfIcon style={{ width: 'auto', height: 'auto' }} />}
              progress={100}
              show={true}
              onClose={() => {}}
            />
          )}
          <Box width="100%" className={classes.filesBox} mt={4}>
            <Typography className={classes.label}>
              <Text className={classes.label} i18nKey="attachedFiles">
                Attached files
              </Text>
            </Typography>
            <Box mt={2} display="flex" flexDirection="column">
              {files && files?.length > 0 ? (
                files.map((attachment: any, index) => {
                  const { url, fileType, fileName } = attachment || {};
                  return (
                    <AttachmentItemPreview
                      fileType={fileType}
                      url={url || ''}
                      file={attachment}
                      menuItems={getMenuItems(url, index)}
                      isEdit={true}
                      fileName={fileName}
                    />
                  );
                })
              ) : (
                <Text i18nKey="noAttachement">No attachment</Text>
              )}
            </Box>
          </Box>
        </Box>
      </ScrollBar>
      <Box
        height="76px"
        display="flex"
        alignItems="center"
        width="100%"
        borderTop="1px solid #EAECF0"
      >
        <EditButtons
          handleEdit={handleSave}
          handleOpen={(e) => {
            e.preventDefault();
            closeModal();
          }}
          editable
          px="2rem"
          isSlide
          loading={loadingUpdateTask}
        />
      </Box>
    </Box>
  );
}
