import {
  makeStyles,
  Box,
  useTheme,
  useMediaQuery,
  Avatar,
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import clsx from 'clsx';
import {
  editorStateFromStringifiedRaw,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { Editor } from 'draft-js';
import Skeleton from 'components/ui/Skeleton';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { getTimeDifference } from 'utils';
import { generateFullName } from './utils';

export enum PARTICIPANT_GOING_STATUS {
  yes = 'yes',
  no = 'no',
  maybe = 'maybe',
}
export const useStyles = makeStyles((theme) => ({
  timeHeader: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('md')]: {
      paddingLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  shape: {
    backgroundColor: (props: any) => '#39C38D',
    width: 10,
    border: '1px solid white',
    height: 10,
    [theme.breakpoints.only('md')]: {
      display: 'none',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'none',
    },
  },
  shapeCircle: {
    borderRadius: '50%',
  },
  rightAligned: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
  title: {
    color: '#F8FAFB',
    fontWeight: 'bold',
    fontSize: (props: { time: number }) => (props?.time < 15 ? '11px' : '12px'),
    textTransform: 'capitalize',
    textDecorationLine: (props: {
      doctorGoing: PARTICIPANT_GOING_STATUS;
      patientGoing: PARTICIPANT_GOING_STATUS;
      patient: boolean;
    }) =>
      ((props.doctorGoing === PARTICIPANT_GOING_STATUS.no && !props?.patient) ||
        (props.patientGoing === PARTICIPANT_GOING_STATUS.no &&
          props?.patient)) &&
      'line-through',
    [theme.breakpoints.only('md')]: {
      marginLeft: theme.spacing(-1),
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(-1),
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: (props: { time: number }) =>
        props?.time < 15 ? '11px' : '12px',
    },
  },
  topSpacing: {
    marginTop: theme.spacing(1),
  },
  main: {
    overflowX: 'hidden',
    maxHeight: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    textDecorationLine: (props: {
      doctorGoing: PARTICIPANT_GOING_STATUS;
      patientGoing: PARTICIPANT_GOING_STATUS;
      patient: boolean;
    }) =>
      ((props.doctorGoing === PARTICIPANT_GOING_STATUS.no && !props?.patient) ||
        (props.patientGoing === PARTICIPANT_GOING_STATUS.no &&
          props?.patient)) &&
      'line-through',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
    },
    fontSize: (props: { time: number }) => (props?.time < 15 ? '11px' : '12px'),
    [theme.breakpoints.only('lg')]: {
      fontSize: (props: { time: number }) =>
        props?.time < 15 ? '11px' : '12px',
    },
  },
}));
interface IExtendedProps {
  firstColor: string;
  description: string;
  _id?: string;
  doctorGoing?: PARTICIPANT_GOING_STATUS;
  patientGoing?: string;
  doctor?: any;
  patient?: any;
  sessionType: {
    rate: string;
    session: string;
    duration: string;
    currency: string;
    __typename?: string;
    location: string;
    locationPlace: string;
    externalUrl: string;
  };
  startDate: string;
  endDate: string;
  title?: string;
  color?: string;
  isPaid?: string;
  summary?: string;
  googleEventId?: string;
  isEnded: boolean;
}
export interface IEventProps {
  extendedProps: IExtendedProps;
  title: string;
  start?: string;
  end?: string;
  id?: string;
  backgroundColor?: string;
  sessionType?: {
    duration: string;
  };
}
export interface ICustomRenderProps {
  event: IEventProps;
  timeText: string;
  eventsLoading: boolean;
}

export default function CustomContentRender({
  event,
  timeText,
  eventsLoading,
}: ICustomRenderProps): ReactElement {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.only('lg'), {
    defaultMatches: true,
  });
  const start = timeText.split('-');
  const time = getTimeDifference(timeText);
  const duration = time <= 30 ? start[0] : timeText;
  const { patient, doctor, user } = useAuth();
  const { title: header, extendedProps } = event || {};
  const classes = useStyles({
    bgColor: event.extendedProps?.firstColor,
    doctorGoing: event.extendedProps?.doctorGoing,
    patientGoing: event.extendedProps?.patientGoing,
    patient,
    time,
  });
  const isPatient = !!patient;
  const title = isPatient
    ? `${generateFullName(isLarge, event.extendedProps?.doctor)}`
    : event?.extendedProps?.summary ||
      `${generateFullName(isLarge, event.extendedProps?.patient)}`;

  return (
    <Skeleton loading={eventsLoading}>
      <Box className={clsx('fc-event-main-frame', classes.main)}>
        {duration && (
          <div className={`${classes.timeHeader}`}>
            <div className={classes.text}>{duration}</div>
            {/* {circle} */}
          </div>
        )}
        <Box pl={1} className="fc-event-title-container">
          {header ? (
            <Box className={clsx('fc-event-title fc-sticky', classes.title)}>
              {header}
            </Box>
          ) : (
            <Box className={clsx('fc-event-title fc-sticky', classes.title)}>
              {title}
            </Box>
          )}
        </Box>
        {['Admin', 'owner']?.includes(user?.role) &&
          doctor?._id !== extendedProps?.doctor?._id && (
            <Box position="absolute" bottom="5px" right="0px">
              <Avatar
                src={extendedProps?.doctor?.picture}
                style={{
                  borderRadius: '50%',
                  marginRight: '10px',
                  width: '20px',
                  height: '20px',
                  fontSize: '10px',
                  textTransform: 'uppercase',
                }}
              >
                {extendedProps?.doctor?.firstname &&
                  extendedProps?.doctor?.firstname[0]}
                {extendedProps?.doctor?.lastname &&
                  extendedProps?.doctor?.lastname[0]}
              </Avatar>
            </Box>
          )}
      </Box>
    </Skeleton>
  );
}
