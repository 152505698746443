import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as VideoIcon } from 'assets/videoActions/videoIcon.svg';

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    color: '#2DC76D',
  } as any,
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: '50%',
    marginRight: theme.spacing(2),
  } as any,
  icon: {
    fill: 'black',
  } as any,
  primaryText: {
    color: theme.palette.black.dark,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  } as any,
}));
interface IRoomAttendedProps {
  primaryText: string;
  secondaryText: string;
  handleClick: () => void;
  handleRemove: () => void;
  picture: string;
}
export default function RoomAttended({
  primaryText,
  secondaryText,
  handleClick,
  handleRemove,
  picture,
}: IRoomAttendedProps) {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemAvatar>
        <img className={classes.small} src={picture} alt="person-avatar" />
      </ListItemAvatar>
      <ListItemText
        classes={{
          primary: classes.primaryText,
          secondary: classes.secondaryText,
        }}
        primary={primaryText}
        secondary={secondaryText}
      />
      <ListItemSecondaryAction>
        <IconButton onClick={handleClick} edge="end" aria-label="delete">
          <VideoIcon stroke-width="2" stroke="#425364" />
        </IconButton>
        {/* <IconButton onClick={handleRemove} edge="end" aria-label="delete">
          <DeleteIcon />
        </IconButton> */}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
