import React, { useEffect, useState } from 'react';
import {
  BoxProps,
  Grid,
  makeStyles,
  Box,
  IconButton,
  Typography,
  InputAdornment,
  FormLabel,
  Chip,
  FormHelperText,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { gql, useQuery } from '@apollo/client';
import { ReactComponent as DateIcon } from 'assets/patientActivities/inputs/date.svg';

import { log } from 'config';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import EditButtons from 'components/EditButtons';
import {
  convertToRawAndStringify,
  convertStringToEditorState,
} from '@eggmed/common/utils/DraftUtils';
import { formatToFullDateWithoutTime } from 'utils';
import ListItemWithActions from 'components/ui/ListItemWithActions';

import { ReactComponent as FolderIcon } from 'assets/icons/foldericon.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/PdfIconFile.svg';

import clsx from 'clsx';
import {
  convertObjectsFormat,
  convertObjectsFormatStaff,
  convertObjectsFormatCurrentUser,
} from 'pages/SchedulePage/AddEventPage/utils';
import AssignedTo from 'components/ui/AssignedTo';
import PriorityChip from '../components/PriorityChip';
import { RegisteredSelect } from '../components/CustomSelect';
import { taskInput } from '../../../../__generated__/globalTypes';
import AttachmentInput from '../components/AttachmentInput';
import useStyles from './styles';
import TaskInput from './TaskInput';
import ScrollBar from 'components/ui/ScrollBar';
import { EditorState } from 'draft-js';
import { useToast } from 'hooks/useToast';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { convertObjFormat } from './utils';
import { useProvider } from '../state/Provider';
import { convertString } from '../List';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import AttachmentInputFile from '../components/AttachementInputFile';
import StatusChip from '../components/StatusChip';
import DateChip from '../components/DateChip';
import UploadFilePreview from 'components/ui/UploadFileUi/UploadFilePreview';
import { MAX_SIZE } from 'components/ui/UploadFileUi/AnimatedProgressBar/AnimatedProgressBar';
import AttachmentItemPreview from '../components/AttachmentItemPreview';
import { ReactComponent as Rename } from 'assets/icons/edit-03.svg';
import { ReactComponent as Download } from 'assets/icons/download-01.svg';
import { ReactComponent as Delete } from 'assets/icons/trash-01.svg';
interface MenuItem {
  url: string;
  avatar: React.ReactNode;
  name: string;
  onClick: () => void;
}
interface ITaskListProps {
  name: string;
  _id: string;
  tasks: string[];
}

interface ITaskAddProps extends BoxProps {
  closeModal: () => void;
  handleAddTask: (data: taskInput, file: File[], listId?: string) => void;
  loadingTask: boolean;
  taskList: ITaskListProps[];
}

const schema = yup.object().shape({
  title: yup
    .string()
    .min(2, 'Title must be at least 2 characters')
    .required('Title is a required field'),
  status: yup.string().required('Status is a required field'),
  priority: yup.string().required('Priority is a required field'),
});

export default function TaskAdd({
  closeModal,
  handleAddTask,
  loadingTask,
  taskList,
  ...props
}: ITaskAddProps): JSX.Element {
  const { t } = useTranslation();
  const { doctor, user } = useAuth();
  const { doctors, patients, users } = useProvider();
  const { openSuccess, triggerToast } = useToast();
  const [files, setFiles] = useState<any>([]);
  const [values, setValues] = useState<any[]>(convertObjectsFormat([user], ''));
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const { triggerSnack } = useSnackbar();
  const [patientValues, setPatientValues] = useState([]);
  const [currentFile, setCurrentFile] = useState();
  const {
    register,
    control,
    errors,
    getValues,
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const classes = useStyles({ openSuccess });
  const dueDate = watch()?.dueDate || new Date(); // Default to current date
  const formattedDate = formatToFullDateWithoutTime(dueDate);

  const handleClose = (index) => {
    const updatedFiles = [...files];
    files.splice(index, 1);
    setUploadingFiles(updatedFiles);
    setFiles(files);
  };
  const handleDelete = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };
  const handleDownload = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    link.click();
  };
  const getMenuItems = (url: string, index: number): MenuItem[] => [
    /* {
      url,
      avatar: <Rename style={{ marginRight: '8px' }} />,
      name: 'Rename',
      onClick: () => console.log(`Rename file at ${url}`),
    },*/
    {
      url,
      avatar: <Download style={{ marginRight: '8px' }} />,
      name: 'Download',
      onClick: () => handleDownload(url, 'file.pdf'),
    },
    {
      url,
      avatar: <Delete style={{ marginRight: '8px' }} />,
      name: 'Delete',
      onClick: () => handleDelete(index),
    },
  ];
  async function onSubmit(data: any) {
    data.assignedTo = values.map(({ id }) => id);
    data.patients = patientValues.map(({ id }) => id);
    try {
      if (values.length > 0) {
        await handleAddTask(
          {
            ...data,
            description: data?.description,
          },
          files.filter((file) => file.size < MAX_SIZE),
          taskList?.find((task) => task?.name == data?.status)?._id
        );
        triggerSnack(t('tasks_created_successfully'));
      }
    } catch (error) {
      log('error', error);
    }
  }
  const commonProps = { register, control, errors, variant: 'filled' };
  return (
    <Box {...props} className={classes.responsiveMobile}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ScrollBar className={classes.scroll}>
          <Box px="2rem">
            <Box style={{ marginTop: '12px' }}>
              <TaskInput
                {...commonProps}
                placeholder={t('placeholderTaskName')}
                type="text"
                data-cy="task-title"
                name="title"
                label={t('nameTask')}
              />
            </Box>
            <Box className={classes.equal}>
              <Box display="flex" mt={4}>
                <FormLabel className={clsx(classes.label)}>
                  <Text className={clsx(classes.label)} i18nKey="status">
                    Status
                  </Text>
                </FormLabel>
              </Box>
              <RegisteredSelect
                data-cy="status-select"
                renderValue={(value: any) => (
                  <StatusChip
                    data-cy="status-view"
                    style={{
                      cursor: 'pointer',
                      borderRadius: '8px',
                    }}
                    priority={convertString[value]}
                  />
                )}
                name="status"
                defaultValue={'overdue'}
                renderOption={(value: any) => (
                  <StatusChip
                    data-cy="status-option"
                    style={{
                      cursor: 'pointer',
                      borderRadius: '8px',
                    }}
                    priority={convertString[value]}
                  />
                )}
                options={['overdue', 'pending', 'completed']}
                control={control}
                register={register}
                style={{ marginTop: '11px', width: '100%' }}
                error={!!errors?.status}
              />
              {errors && errors['status'] && (
                <FormHelperText className={classes.error}>
                  <ErrorIcon className={classes.errorIcon} />
                  {t((errors as any)['status'].message)}
                </FormHelperText>
              )}
            </Box>
            <Box className={classes.labelSpacingAssigned}>
              <Text
                i18nKey="assignee(s)"
                className={clsx(classes.label, classes.spacingIcon)}
              >
                Assignee(s)
              </Text>
            </Box>
            <Box style={{ marginTop: '-6px' }}>
              <Symptoms
                img={true}
                type=""
                disableCustomFilter={true}
                options={users}
                values={values}
                setValues={setValues}
                hasErrors={values.length === 0}
              />
              <Box>
                {values.length === 0 && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {t('Assign to is a required field')}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box className={classes.labelSpacingAssigned}>
              <Text
                i18nKey="relatedClient(s)"
                className={clsx(classes.label, classes.spacingIcon)}
              >
                Client(s)
              </Text>
            </Box>
            <Box style={{ marginTop: '-6px' }}>
              <Symptoms
                patient={true}
                disableCustomFilter={true}
                options={convertObjectsFormat(patients, '')}
                values={patientValues}
                setValues={setPatientValues}
              />
            </Box>
            <div style={{ display: 'inline-flex', width: '100%', gap: '20px' }}>
              <Box width="50%">
                <TaskInput
                  type="date"
                  name="dueDate"
                  label={t('Due date')}
                  style={{ display: 'flex' }}
                  minDate={new Date()}
                  defaultValue={new Date()}
                  className={classes.inputDate}
                  customInput={
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      className={classes.inputWrapperCustom}
                    >
                      <DateChip
                        data-cy="date-view"
                        className={dueDate ? classes.dateChip : classes.noDate}
                        label={formattedDate}
                      />
                      <InputAdornment
                        style={{ marginLeft: 'auto' }}
                        position="end"
                      >
                        <IconButton aria-label="calendar">
                          <DateIcon />
                        </IconButton>
                      </InputAdornment>
                    </Box>
                  }
                  {...commonProps}
                />
              </Box>
              <Box width="50%" className={classes.container}>
                <Box className={classes.equal}>
                  <Box display="flex" mt={4}>
                    <FormLabel className={clsx(classes.label)}>
                      <Text className={clsx(classes.label)} i18nKey="priority">
                        Priority
                      </Text>
                    </FormLabel>
                  </Box>
                  <RegisteredSelect
                    data-cy="task-priority"
                    renderValue={(value: any) => (
                      <PriorityChip
                        data-cy="priority-view"
                        className={classes.chip}
                        priority={value}
                      />
                    )}
                    name="priority"
                    defaultValue={'low'}
                    renderOption={(value: any) => (
                      <PriorityChip className={classes.chip} priority={value} />
                    )}
                    options={['high', 'medium', 'low']}
                    control={control}
                    register={register}
                    style={{ marginTop: '10px', width: '100%' }}
                    error={!!errors?.priority}
                  />
                  {errors && errors['priority'] && (
                    <FormHelperText className={classes.error}>
                      <ErrorIcon className={classes.errorIcon} />
                      {t((errors as any)['priority'].message)}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            </div>
            <TaskInput
              {...commonProps}
              className={classes.draftjsInput}
              name="description"
              type="area"
              multiline
              minRows={10}
              placeholder={t('placeholderDescription')}
              label={t('Description')}
            />
            <Box display="flex" alignItems="center" mt={3}>
              <FormLabel className={clsx(classes.label)}>
                <Text className={clsx(classes.label)} i18nKey="attachements">
                  Attachment(s)
                </Text>
              </FormLabel>
            </Box>
            <AttachmentInputFile
              data-cy="file-add"
              my={2}
              namePart1={t('ClickUpload')}
              namePart2={t('Drag&Drop')}
              max_size={t('MaxSize')}
              isActionable
              time={files.length > 0}
              createdAt={new Date()}
              handleActionDragAndDrop={(event: any) => {
                setCurrentFile(null);
                if (event?.dataTransfer?.files[0].size < MAX_SIZE) {
                  const newList = [...files, event.dataTransfer?.files[0]];
                  setFiles(newList);
                }
                setCurrentFile(event.dataTransfer?.files[0]);
              }}
              handleAction={(event: any) => {
                setCurrentFile(null);
                if (event?.target?.files[0].size < MAX_SIZE) {
                  const newList = [...files, event.target?.files[0]];
                  setFiles(newList);
                }
                setCurrentFile(event.target?.files[0]);
              }}
            />
            {currentFile && (
              <UploadFilePreview
                file={currentFile}
                url={''}
                isEdit={false}
                icon={<PdfIcon style={{ width: 'auto', height: 'auto' }} />}
                progress={100}
                setCurrentFile={setCurrentFile}
                show={true}
                onClose={() => {}}
              />
            )}

            {files &&
              files.map((file, index) => (
                <AttachmentItemPreview
                  fileType={file.type}
                  fileSize={file.size}
                  file={file}
                  isEdit={false}
                  url={URL.createObjectURL(file)}
                  fileName={file.name}
                  menuItems={getMenuItems(URL.createObjectURL(file), index)}
                />
              ))}
          </Box>
        </ScrollBar>
        <Box
          height="76px"
          display="flex"
          alignItems="center"
          width="100%"
          borderTop="1px solid #EAECF0"
        >
          <EditButtons
            handleOpen={(e) => {
              e.preventDefault();
              closeModal();
            }}
            editable
            loading={loadingTask}
            px="2rem"
            isSlide
          />
        </Box>
      </form>
    </Box>
  );
}
