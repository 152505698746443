import React, { ReactElement } from 'react';
import { Controller, Control } from 'react-hook-form';
import getYear from 'date-fns/getYear';
import DatePicker from 'react-datepicker';
import range from 'lodash.range';
import getMonth from 'date-fns/getMonth';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles,
  NativeSelect,
  withStyles,
} from '@material-ui/core';
import { ReactComponent as DateIcon } from 'assets/patientActivities/inputs/date.svg';

import { FormInput } from './FormInput';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: '100%',

    '& div.react-datepicker-wrapper': {
      width: '100%',
      zIndex: 1000,
    },
    '& .react-datepicker-popper': {
      zIndex: 50000000000000,
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: '#39C38D',
      color: 'white !important',
      borderRadius: '30px',
    },
    '& .react-datepicker__day-name': {
      color: '#425364',
      fontWeight: 500,
    },
    '& .react-datepicker__header': {
      backgroundColor: '#ffffff',
      borderBottom: '0px',
    },
    '& .react-datepicker__day': {
      color: '#425364',
      fontWeight: 500,
    },
    '& .react-datepicker__day--outside-month': {
      color: '#949494',
      fontWeight: 500,
    },
    '& .react-datepicker__day[aria-disabled="true"]': {
      color: '#949494',
      fontWeight: 500,
    },
    '& .react-datepicker__triangle': {
      borderBottom: '1px solid #ffffff',
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before':
      {
        borderBottom: '1px solid #fbfbfb',
        display: 'none',
      },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: 'white !important',
    },
  },
  error: {
    color: 'red',
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.03333em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  nextButton: {
    border: '1px solid #DEE2EC',
    color: '#425364',
    backgroundColor: 'white',
    width: '20.7px',
    height: '20.7px',
    fontSize: '16px',
    borderRadius: '10px',
    // margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  select: {
    '& > select': {
      height: '1.3rem',
    },
  },
}));
export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    // width: '30px',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 13,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export const Select = ({ handleChange, value, options }: any) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.margin}>
      <NativeSelect
        id="demo-customized-select-native"
        value={value}
        onChange={handleChange}
        input={<BootstrapInput />}
        className={classes.select}
      >
        {options.map((element: any) => (
          <option key={element} value={element}>
            {element}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

interface ICustomDateProps {
  name: string;
  label?: string;
  style?: StyleSheetList;
  value: string | number | Date;
  onChange?: any;
  onBlur?: (e: any) => void;
  dateFormat?: string;
  showTimeSelect?: boolean;
  excludedTimes?: Date[];
  minTime?: Date;
  maxTime?: Date;
  minDate?: Date;
  feedMode?: boolean;
}
interface IControllerCustomDateProps extends ICustomDateProps {
  control: Control;
  defaultValue?: Date;
  helperText?: any;
  error?: boolean;
  minDate?: Date;
}

export default function CustomDate({
  control,
  name,
  defaultValue = new Date(),
  label,
  style,
  value,
  helperText,
  error,
  minDate,
  ...props
}: IControllerCustomDateProps): ReactElement {
  const years = range(1890, getYear(new Date()) + 2, 1);
  const { t } = useTranslation();
  const months = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ];
  const classes = useStyles();
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      {...props}
      render={({ onChange, onBlur, value }) => (
        <div className={classes.datePicker}>
          <DatePicker
            data-cy="date-input"
            minDate={minDate}
            // showTimeSelect
            customInput={
              <FormInput
                fullWidth
                data-cy="date-input"
                style={{ width: '100%', ...style }}
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="date">
                        <DateIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            popperProps={{
              positionFixed: true,
            }}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            name={name}
            onBlur={onBlur}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }: any) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  className={classes.nextButton}
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </IconButton>

                <Select
                  options={years}
                  handleChange={({ target: { value } }: any) =>
                    changeYear(value)
                  }
                  value={getYear(date)}
                />
                <Select
                  options={months}
                  handleChange={({ target: { value } }: any) =>
                    changeMonth(months.indexOf(value))
                  }
                  value={months[getMonth(date)]}
                />
                <IconButton
                  className={classes.nextButton}
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </IconButton>
              </div>
            )}
            selected={value ? new Date(value) : null}
            onChange={onChange}
            {...props}
          />
          <div className={classes.error}>{error && helperText}</div>
        </div>
      )}
    />
  );
}

export function UncontrollerCustomDate({
  name,
  value,
  onChange,
  onBlur,
  dateFormat,
  style,
  showTimeSelect,
  excludedTimes,
  minTime,
  maxTime,
  minDate,
  feedMode = false,
}: ICustomDateProps): JSX.Element {
  const { t } = useTranslation();
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ];
  const classes = useStyles();

  return (
    <div className={classes.datePicker}>
      <DatePicker
        disabled={feedMode}
        data-cy="date-input"
        excludeTimes={excludedTimes as Date[]}
        minTime={minTime}
        maxTime={maxTime}
        minDate={minDate}
        customInput={
          <FormInput
            data-cy="date-input"
            fullWidth
            style={{ width: '100%', ...style }}
            variant="filled"
          />
        }
        popperProps={{
          positionFixed: true,
        }}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        showTimeSelect={showTimeSelect}
        dateFormat={dateFormat}
        name={name}
        onBlur={onBlur}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }: any) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton
              className={classes.nextButton}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </IconButton>

            <Select
              options={years}
              handleChange={({ target: { value } }: any) => changeYear(value)}
              value={getYear(date)}
            />
            <Select
              options={months}
              handleChange={({ target: { value } }: any) =>
                changeMonth(months.indexOf(value))
              }
              value={months[getMonth(date)]}
            />
            <IconButton
              className={classes.nextButton}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </IconButton>
          </div>
        )}
        selected={new Date(value)}
        onChange={onChange}
      />
    </div>
  );
}
