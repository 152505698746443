import React, { ReactElement } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Backdrop,
  Modal,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from 'components/ui/Button';
import clsx from 'clsx';
import { ReactComponent as Alert } from 'assets/blueAlert.svg';
import { ReactComponent as Close } from 'assets/close-icon.svg';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  modalDisconTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: '#101828',
  },
  Modalcontainer: {
    paddingInline: '2rem',
    paddingBottom: '2rem',
    width: '25vw',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  cancel: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px 20px',
    gap: '10px',

    height: '44px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    flex: 'none',
    order: 0,
    flexGrow: 1,
    color: '#000000',
    textTransform: 'none',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  continue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px 20px',
    gap: '10px',
    height: '44px',
    background: '#0265DC',
    borderRadius: '8px',
    flex: 'none',
    order: 1,
    flexGrow: 1,
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      background: '#0265DC',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    padding: '36px',
    gap: '24px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    border: '1px solid #DEE2E6',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    zIndex: 1000,
    '&:focus-within': {
      outline: 'none',
    },
  },
  subtitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#475467',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    width: '100%',
    gap: '18px',
    flex: 'none',
    order: 2,
    alignSelf: 'stretch',
    flexGrow: 0,
  },
  close: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    position: 'absolute',
    top: '10%',
    right: '1%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
  },
}));
const FreeModal = ({ handleClose, handleFree, open }): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClosePopUp = () => {
    handleClose();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableBackdropClick
      BackdropProps={{
        timeout: 500,
        onClick: (event) => event.stopPropagation(),
      }}
      disableScrollLock={false}
      open={open}
    >
      <Box className={classes.box}>
        <Box className={classes.close} onClick={handleClose}>
          <Close />
        </Box>
        <Alert />
        <Box display="flex" mt={1}>
          <Box>
            <Typography className={classes.modalDisconTitle}>
              {t('Set this service as free?')}
            </Typography>
            <Typography className={classes.subtitle}>
              {t(
                'If you set this service as free, prepayment will no longer be an option.'
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.buttons} mt={1}>
          <Button className={classes.cancel} onClick={handleClosePopUp}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleFree} className={classes.continue}>
            {t('Continue')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FreeModal;
